import React, { FunctionComponent } from 'react';
import Page from '../components/Page';

const Rettigheder: FunctionComponent = (props) => {

    return (
        <Page>
            <div className="container alert alert-info " role="alert"
                aria-label="Beskedbox der viser information">
                <div className="alert-body">
                    <p className="alert-heading ">Du har ikke rettigheder til at bruge Sprogprøver - Indberetning</p>
                    <div className="alert-text">
                        <p>
                            Kontakt din skoleledelse, hvis du mener, at du skal kunne se siden. Skoleledelsen kan give dig nye brugerettigheder i skolens adminstrative system.
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default Rettigheder;
