import React, { FunctionComponent, useState } from 'react';

type OnTjekboksChangePropType = (value: boolean) => void;

type TjekboksProps = {
    id: string,
    changed?: OnTjekboksChangePropType,
    value: string,
    label: string,
    checked: boolean,
    inputClassName?: string,
    labelClassName?: string
};

const Tjekboks: FunctionComponent<TjekboksProps> = (props) => {
    const [checked, setChecked] = useState<boolean>(props.checked);

    function onChangeEvent(event) {
        setChecked(event.target.checked);
        if (props.changed) {
            props.changed(event.target.checked);
        }
    }

    return (
        <div>
            <input key={props.id} id={"tb_" + props.id} onChange={(e) => onChangeEvent(e)} checked={checked} value={props.value} type="checkbox" className={props.inputClassName} aria-label="Checkbox"/>
            <label htmlFor={"tb_" + props.id} className={props.labelClassName}>{props.label}</label>
        </div>
    );
}

export default Tjekboks;