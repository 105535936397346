import { ElevUdpegningData } from "./EleverTyper";

type ElevData = {
    navn: string,
    unilogin: string,
    klassenavn: string,
    klassetrin: number
} | ElevUdpegningData;

export function sortElevData(data) {
    return data.sort((e1: ElevData, e2: ElevData) => {
        if (e1.klassetrin < e2.klassetrin) return -1;
        if (e1.klassetrin > e2.klassetrin) return 1;
        if (e1.klassenavn < e2.klassenavn) return -1;
        if (e1.klassenavn > e2.klassenavn) return 1;
        if (e1.navn < e2.navn) return -1;
        if (e1.navn > e2.navn) return 1;
        return 0;
    });
}
  