import React, { ErrorInfo } from 'react';
import { LogError } from '../services/ErrorService';
import Footer from './Footer';
import Header from './Header';

type ErrorBoundaryState = {
    hasError: boolean,
    message: string
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            message: ""
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ message: error.message });
        LogError(error.stack ? error.stack : error.message);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <Header navigation={false} loginButton={false} />

                    <div className="container alert alert-error" role="alert">
                        <div className="alert-body">
                            <p className="alert-heading">Undskyld. Der er desværre opstået en fejl.</p>
                            <div className="alert-text">
                                Du kan komme videre ved at klikke på linket til forsiden:
                                <p>
                                    <a href="/">Gå til forsiden</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
