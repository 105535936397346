import React, {
    FunctionComponent,
    useState,
    useEffect,
    ReactElement,
} from "react";
import Page from "../components/Page";
import PageLoader from "../components/PageLoader";
import Table from "../components/common/Table";
import ApiService from "../services/ApiService";
import { BackToStartLink } from "./Overblik";
import Dropdown from "../components/common/Dropdown";
import Radioknapper from "../components/common/Radioknapper";
import Searchbox from "../components/common/Searchbox";
import { sortElevData } from "../helpers/SortHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Micromodal from "../components/common/Micromodal";
import Popover from "../components/common/Popover";
import { ElevUdpegningData } from "../helpers/EleverTyper";
import { useAuthenticationStore } from "../context/store";

type ElevData =
    | {
          navn: string;
          unilogin: string;
          klassenavn: string;
          klassetrin: number;
      }
    | ElevUdpegningData;

const headlineIndreStregStyle = {
    borderBottom: "1px solid #BFBFBF",
};

const Udpegning: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const skoleaar = authenticationStore.getSkoleAar();
    const [elevData, setElevData] = useState<ElevUdpegningData[]>(
        new Array<ElevUdpegningData>()
    );
    const [filteredElevData, setFilteredElevData] = useState<
        ElevUdpegningData[] | undefined
    >(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [opdateringer, setOpdateringer] = useState<ElevUdpegningData[]>(
        new Array<ElevUdpegningData>()
    );
    const [micromodalMode, setMicromodalMode] = useState<boolean>(false);
    const [micromodalElev, setMicromodalElev] = useState<
        ElevUdpegningData | undefined
    >(undefined);

    useEffect(() => {
        async function callApi() {
            ApiService<ElevUdpegningData[]>(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/hentudpegninger/" +
                    skoleaar
            )
                .then((response) => {
                    setFilteredElevData(response);
                    setElevData(response);
                })
                .catch((e) => {
                    setError(e);
                });
        }

        callApi();
    }, [
        authenticationStore.authenticatedUser,
        opdateringer,
        skoleaar,
        authenticationStore.aktivInstitution,
    ]);

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        const targetValue = e.target.value;

        if (targetValue.length === 0) {
            setFilteredElevData(elevData);
        } else {
            const newFilter = targetValue.toUpperCase();
            let newElevData = elevData?.filter((e) => {
                return (
                    e.navn.toUpperCase().indexOf(newFilter) > -1 ||
                    e.klassenavn.toUpperCase().indexOf(newFilter) > -1 ||
                    (e.udpeget ? "JA" : "NEJ").toString().indexOf(newFilter) >
                        -1 ||
                    (e.udpeget
                        ? e.klassetrinUdpegning ?? e.klassetrin
                        : e.klassetrin
                    )
                        .toString()
                        .indexOf(newFilter) > -1
                );
            });
            setFilteredElevData(newElevData);
        }
    }

    function changeKlassetrin(selectid: string, klassetrin: string) {
        var unilogin = selectid.substr("klassetrin_".length);
        var opdatering = opdateringer.filter((o) => o.unilogin === unilogin);
        if (opdatering.length === 0) {
            const elevDataChange = elevData.filter(
                (e) => e.unilogin === unilogin
            )[0];
            const newElevData: ElevUdpegningData = {
                navn: elevDataChange.navn,
                klassenavn: elevDataChange.klassenavn,
                klassetrin: Number(klassetrin),
                unilogin: elevDataChange.unilogin,
                udpeget: elevDataChange.udpeget,
            };
            opdateringer.push(newElevData);
        } else {
            opdatering[0].klassetrin = Number(klassetrin);
        }
    }

    function changeUdpeget(selectid: string, value: string) {
        var boolValue = value === "Ja" ? true : false;
        var unilogin = selectid.substr("udpeget_".length);
        var opdatering = opdateringer.filter((o) => o.unilogin === unilogin);
        if (opdatering.length === 0) {
            const elevDataChange = elevData.filter(
                (e) => e.unilogin === unilogin
            )[0];
            const newElevData: ElevUdpegningData = {
                navn: elevDataChange.navn,
                klassenavn: elevDataChange.klassenavn,
                klassetrin: elevDataChange.klassetrin,
                unilogin: elevDataChange.unilogin,
                udpeget: boolValue,
                klassetrinUdpegning: elevDataChange.klassetrinUdpegning,
            };
            opdateringer.push(newElevData);
        } else {
            opdatering[0].udpeget = boolValue;
        }
    }

    function renderRows(filteredData) {
        const nameDisplay = (elev: ElevUdpegningData): string => {
            var navne = filteredData?.filter((e) => e.navn === elev.navn);
            if (navne && navne.length > 1)
                return elev.navn + " (" + elev.unilogin + ")";
            return elev.navn;
        };
        return sortElevData(filteredData).map((elev: ElevUdpegningData) => {
            let cells: Array<ReactElement | string> = [
                nameDisplay(elev),
                elev.klassenavn,
            ];
            if (editMode) {
                cells.push(
                    <Dropdown
                        selected={
                            elev.udpeget
                                ? elev.klassetrinUdpegning ?? elev.klassetrin
                                : elev.klassetrin
                        }
                        selectid={"klassetrin_" + elev.unilogin}
                        options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                        onChangeProp={changeKlassetrin}
                    />
                );
                cells.push(
                    <Radioknapper
                        selected={elev.udpeget ? "Ja" : "Nej"}
                        options={["Nej", "Ja"]}
                        selectid={"udpeget_" + elev.unilogin}
                        groupClassName="inline-radio"
                        buttonClassName="form-radio radio-large"
                        onChangeProp={changeUdpeget}
                    />
                );
            } else {
                cells.push(
                    String(
                        elev.udpeget
                            ? elev.klassetrinUdpegning
                            : elev.klassetrin
                    )
                );
                cells.push(elev.udpeget ? "Ja" : "Nej");
                cells.push(
                    <button
                        className="button-unstyled"
                        onClick={() => openIndvidRedigering(elev)}
                        aria-label="Rediger udpegning"
                    >
                        <FontAwesomeIcon
                            icon={["fas", "ellipsis-v"]}
                            aria-label="Rediger udpegning"
                        />
                        <span style={{ display: "none" }}>
                            Udvælg elev knap
                        </span>
                    </button>
                );
            }
            return { className: "", cells };
        });
    }

    function annuller() {
        setMicromodalMode(false);
        setEditMode(false);
        setOpdateringer(new Array<ElevUdpegningData>());
    }

    function saveUpdate() {
        //send
        console.log(
            "Sender fra inst",
            authenticationStore.aktivInstitution,
            authenticationStore.aktivInstitution.institutionsnummer
        );

        ApiService<boolean>(
            "institution/" +
                authenticationStore.aktivInstitution.institutionsnummer +
                "/opdaterudpegninger/" +
                skoleaar,
            false,
            "post",
            opdateringer
        )
            .then((response) => {
                if (response === false) throw error;
                setOpdateringer(new Array<ElevUdpegningData>());
                setEditMode(false);
                setMicromodalMode(false);
            })
            .catch((e) => {
                setError(e);
            });
    }

    function openIndvidRedigering(elev: ElevUdpegningData) {
        setMicromodalElev(elev);
        setMicromodalMode(true);
    }

    const micromodalTitle =
        micromodalElev !== undefined
            ? micromodalElev.navn +
              ", " +
              micromodalElev.klassenavn +
              " (skoleåret " +
              skoleaar +
              "/" +
              (skoleaar + 1)
            : "Hov!";

    const micromodalHjaelp =
        'Hvis du først registrerer resultater i skoleåret efter, at prøven er gennemført, skal du nedjustere "klassetrin for indberetning". På den måde sikrer du, at elevens "klassetrin for indberetning" svarer til det klassetrin, hvor sprogprøven er gennemført.';

    const micromodalContent =
        micromodalElev !== undefined ? (
            <div className="row">
                <div className="col">
                    <h2 className="h4">Skal til sprogprøve</h2>
                    {micromodalElev.klassetrin === 0 ? (
                        <span className="form-hint">
                            Alle elever i børnehaveklassen skal til sprogprøve.
                            Du må derfor kun vælge Nej, hvis eleven er fritaget
                            fra sprogprøven i skoleåret {skoleaar}/
                            {skoleaar + 1} i overensstemmelse med §14 i
                            bekendtgørelse om obligatoriske sprogprøver i
                            grundskolen.
                        </span>
                    ) : null}
                    <div className="mt-3">
                        <Radioknapper
                            selected={micromodalElev.udpeget ? "Ja" : "Nej"}
                            options={["Ja", "Nej"]}
                            selectid={"udpeget_" + micromodalElev.unilogin}
                            groupClassName="small-text"
                            buttonClassName="form-radio radio-large"
                            onChangeProp={changeUdpeget}
                        ></Radioknapper>
                    </div>
                </div>
                <div className="col">
                    <h2 className="h4">Klassetrin for indberetning</h2>
                    <span className="form-hint">
                        Du kan ændre elevens klassetrin, så det svarer til det
                        klassetrin, eleven har taget sprogprøven på.{" "}
                        <Popover
                            icon="question-circle"
                            tekst={micromodalHjaelp}
                        ></Popover>
                    </span>
                    <Dropdown
                        selected={
                            micromodalElev.klassetrinUdpegning ??
                            micromodalElev.klassetrin
                        }
                        selectid={"klassetrin_" + micromodalElev.unilogin}
                        options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                        width={"65px"}
                        onChangeProp={changeKlassetrin}
                    />
                </div>
            </div>
        ) : (
            <div>Der er sket en fejl.</div>
        );

    const micromodalFooter = (
        <div>
            <button
                className="button button-primary"
                aria-label="Fx bekræft handling"
                onClick={() => saveUpdate()}
            >
                Gem
            </button>
            <button
                className="button button-secondary"
                data-micromodal-close
                onClick={() => annuller()}
                aria-label="Fx lukker modal vinduet"
            >
                Annuller
            </button>
        </div>
    );

    const micromodalHtml =
        micromodalMode && micromodalElev !== undefined ? (
            <Micromodal
                id="udp-modal"
                status={true}
                elev={micromodalElev}
                title={micromodalTitle}
                content={micromodalContent}
                footer={micromodalFooter}
                onCloseModal={() => annuller()}
            />
        ) : null;

    const PageContent = (elevData: ElevUdpegningData[] | undefined) => {
        let table = <p>Ingen elever</p>;
        if (elevData !== undefined && elevData.length > 0) {
            let headers = [
                "Elev",
                "Klasse",
                "Klassetrin for indberetning",
                "Skal til sprogprøve",
            ];
            if (!editMode) {
                headers.push("");
            }
            const rows = renderRows(filteredElevData);
            table = (
                <Table
                    id="elever_1_9"
                    className="table--borderless"
                    headers={headers}
                    dataLabels={[]}
                    widths={["300px"]}
                    alignments={[]}
                    wordBreaks={["break-all"]}
                    rows={rows}
                    footer={""}
                />
            );
        }
        const redigerknap = editMode ? (
            <div style={{ float: "right" }}>
                <button
                    className="button button-primary"
                    onClick={() => saveUpdate()}
                >
                    Gem
                </button>
                <button
                    className="button button-secondary"
                    onClick={() => annuller()}
                >
                    Annuller
                </button>
            </div>
        ) : (
            <div style={{ float: "right" }}>
                <button
                    className="button button-primary"
                    onClick={() => setEditMode(true)}
                >
                    Rediger
                </button>
            </div>
        );

        return (
            <Page>
                <div className="container">
                    <BackToStartLink />
                    <div className="page-container">
                        <h1>Udvælg elever, som skal til sprogprøve</h1>
                        <div className="row">
                            <div className="col-3">
                                <h2 style={headlineIndreStregStyle}>Filtre</h2>
                                <Searchbox
                                    expanded={true}
                                    title="Søg"
                                    helpText="Fx del af elevnavn og klasse"
                                >
                                    <input
                                        className="form-input input-width-s"
                                        type="search"
                                        onChange={(e) => handleSearch(e)}
                                        aria-label="Search"
                                    ></input>
                                </Searchbox>
                            </div>
                            <div className="col">
                                <h2 style={headlineIndreStregStyle}>Elever</h2>
                                {redigerknap}
                                {table}
                            </div>
                        </div>
                    </div>
                </div>
                {micromodalHtml}
            </Page>
        );
    };

    return (
        <PageLoader dataPresent={elevData} error={error}>
            {PageContent(elevData)}
        </PageLoader>
    );
};

export default Udpegning;
