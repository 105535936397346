import ApiService from "../services/ApiService";

class ClientConfiguration {
    clientdata!: ReactClientConfiguration;

    /**
     * This function might be called multiple times upon initialization of the app.
     */
    async getData() {
        if (!this.clientdata) {
            let response = await ApiService<ReactClientConfiguration>(
                "reactclientconfiguration/reactclientconfiguration"
            );
            let config = response;
            this.clientdata = config;
        }

        return this.clientdata;
    }
    /**
     * Compare configuration properties of @param c1 to @param c2
     *
     * @param c1
     * @param c2
     */
    compareReactClientConfiguration(
        c1: ReactClientConfiguration,
        c2: ReactClientConfiguration
    ) {
        return (
            c1.publicUrl === c2.publicUrl &&
            c1.loggingEndpoint === c2.loggingEndpoint &&
            c1.apiEndpoint === c2.apiEndpoint &&
            c1.loginConfigurations === c2.loginConfigurations
        );
    }
}

export interface ReactClientConfiguration {
    publicUrl: string;
    loggingEndpoint: string;
    apiEndpoint: string;
    loginConfigurations?: LoginConfiguration[];
}
export interface LoginConfiguration {
    endpoint: string;
    heading?: string;
    buttonText?: string;
}

const config = new ClientConfiguration();
export default config;
