import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import PageIsLoading from './PageIsLoading';
import { MenuSetup, Side } from '../helpers/RouteSetup';

type PageLoaderProps = {
    dataPresent: any;
    error: Error | undefined;
}

const PageLoader: FunctionComponent<PageLoaderProps> = (props) => {
    if(props.error !== undefined) {
        if(props.error.message.endsWith("403")) {
            return <Redirect to={{ pathname: MenuSetup[Side.rettigheder].path, state: props.error}}  />
        } else {
            return <Redirect to={{ pathname: MenuSetup[Side.fejl].path, state: props.error}}  />
        }
    } else 
    if (props.dataPresent === undefined) {
        return <PageIsLoading />    
    } else 
    return (
        <div>
            {props.children}
        </div>
    );
}

export default PageLoader;
