import React, { FunctionComponent, useState, useEffect } from "react";
import "../assets/stylesheets/Indberetning.css";
import ApiService from "../services/ApiService";
import PageLoader from "../components/PageLoader";
import Page from "../components/Page";
import Sidenavigation from "../components/common/Sidenavigation";
import { Side, MenuSetup } from "../helpers/RouteSetup";
import { useHistory } from "react-router-dom";
import Tilbage from "../components/common/Tilbage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { indberetningsFristdato } from "../utils/AppConst";
import { useAuthenticationStore } from "../context/store";

type OpsummeringData = {
    udpeget: number;
    sprogproevet: number;
    sprogparat: number;
    fritaget: number;

    sommerskole: number;
    ikkeSprogparat0: number;
    tilladet: number;

    ikkeSprogparat19: number;
    oprykket: number;
};

export const Indberetter4 = ({
    data,
}: {
    data: OpsummeringData | undefined;
}) => {
    const authenticationStore = useAuthenticationStore();
    const skoleaar = authenticationStore.getSkoleAar();
    const opsummering = () => {
        let info = (
            <p>
                Når du klikker på Indberet-knappen, bliver skolens oplysninger
                indberettet. Du kan redigere i indberetningen indtil den{" "}
                {indberetningsFristdato}. Hvis du ændrer oplysninger på trin 1 -
                3, skal du huske at klikke på Indberet-knappen igen.
            </p>
        );
        if (data !== undefined) {
            info = (
                <div>
                    <h2 className="h4">Elever i børnehaveklassen</h2>
                    <p>
                        <b>X</b> elever skal til sprogprøven i børnehaveklassen
                    </p>
                    <p>
                        <b>X</b> elever er vurderet sprogparate ved sprogprøven
                        i børnehaveklassen
                    </p>
                    <p>
                        <b>X</b> elever er ikke vurderet sprogparate
                    </p>
                    <p>
                        <b>X</b> elever i børnehaveklassen har deltaget i
                        frivillig sommerskole
                    </p>
                    <p>
                        <b>X</b> elever er registreret til at have fået særlig
                        tilladelse til at påbegynde 1. klasse efter ikke at have
                        bestået 3. eller 4. forsøg, jf. §11a, stk. 5 i
                        folkeskoleloven.
                    </p>
                    <p>
                        <b>X</b> elever rykker op til næste klassetrin
                    </p>
                    <p>
                        <b>X</b> elever rykker ikke op til næste klassetrin
                    </p>
                    <h2 className="h4">Elever i 1. - 9. klasse</h2>
                    <p>
                        <b>X</b> elever skal til sprogprøven i 1. - 9. klasse
                    </p>
                    <p>
                        <b>X</b> elever er vurderet sprogparate ved sprogprøven
                        i 1. - 9. klasse
                    </p>
                    <p>
                        <b>X</b> elever er ikke vurderet sprogparate efter 4.
                        forsøg
                    </p>
                    <p>
                        <b>X</b> elever rykker op til næste klassetrin
                    </p>
                    <p>
                        <b>X</b> elever rykker ikke op til næste klassetrin
                    </p>
                    <h2 className="h4">Elever i 1. - 9. klasse</h2>
                    <p>
                        <b>X</b> elever er fritaget fra at gennemføre
                        sprogprøven
                    </p>
                </div>
            );
        }
        return info;
    };

    return (
        <div>
            <p className="h6 m-0">INDBERET SKOLENS RESULTATER</p>
            <h1>
                Indberetning for skoleåret {skoleaar}/{skoleaar + 1}
            </h1>
            {opsummering()}
        </div>
    );
};

const IndberetterTrin4: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const skoleaar = authenticationStore.getSkoleAar();
    const [opsummeringData, setOpsummeringData] = useState<
        OpsummeringData | undefined
    >(undefined);
    const [indberettet, setIndberettet] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        async function callApi() {
            ApiService<OpsummeringData>(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/hentindberetning/" +
                    skoleaar +
                    "/opsummering"
            )
                .then((response) => {
                    setOpsummeringData(undefined);
                })
                .catch((e) => {
                    setError(e);
                });
        }
        callApi();
    }, [
        authenticationStore.authenticatedUser,
        authenticationStore.aktivInstitution,
        indberettet,
        skoleaar,
    ]);

    function indberet() {
        //send
        ApiService<boolean>(
            "institution/" +
                authenticationStore.aktivInstitution.institutionsnummer +
                "/indberetning/" +
                skoleaar +
                "/trin/4",
            false,
            "post"
        )
            .then((response) => {
                setIndberettet(true);
                if (response === false) throw error;
                else {
                    setSuccess(true);
                    history.push(MenuSetup[Side.indberetningKvittering].path);
                }
            })
            .catch((e) => {
                setError(e);
            });
    }

    const next = (side: number) => {
        history.push(MenuSetup[side].path);
    };

    return (
        <PageLoader dataPresent={null} error={error}>
            <Page>
                <div className="container page-container sp-indb-container">
                    <div className="sidenav sp-indb-sidenav">
                        <Sidenavigation
                            menuIds={[
                                Side.indberetterTrin1,
                                Side.indberetterTrin2,
                                Side.indberetterTrin3,
                                Side.indberetterTrin4,
                            ]}
                            onClick={(side) => next(side)}
                            navStyle={{ width: "240px" }}
                        ></Sidenavigation>
                    </div>
                    <div className="content sp-indb-content">
                        <Indberetter4 data={opsummeringData} />
                        <div style={{ marginTop: "20px" }}>
                            <button
                                className={
                                    indberettet
                                        ? "button button-primary disabled"
                                        : "button button-primary"
                                }
                                onClick={() => indberet()}
                            >
                                Indberet
                            </button>
                            {success ? (
                                <FontAwesomeIcon
                                    icon={["fas", "check"]}
                                    className="ml-2 fa-xl"
                                    aria-hidden="true"
                                    focusable="false"
                                    color="green"
                                />
                            ) : null}
                        </div>
                        <div className="navbar-inner navbar-context-actions">
                            <Tilbage
                                text="Tilbage"
                                onClick={() => next(Side.indberetterTrin3)}
                            />
                        </div>
                    </div>
                </div>
            </Page>
        </PageLoader>
    );
};

export default IndberetterTrin4;
