import React, { FunctionComponent, useEffect, useState } from "react";
import Page from "../components/Page";
import { Redirect, useHistory } from "react-router-dom";
import { MenuSetup, Side } from "../helpers/RouteSetup";
import { useAuthenticationStore } from "../context/store";
import ApiService from "../services/ApiService";
import { Institution } from "../types/AppTypes";

const VaelgSkole: FunctionComponent = () => {
    const [institutioner, setInstitutioner] = useState<string[]>([]);
    const authenticationStore = useAuthenticationStore();

    useEffect(() => {
        let isSubscribed = true;
        async function callApi() {
            ApiService<string[]>("institution/list")
                .then((response) => {
                    if (isSubscribed) {
                        setInstitutioner(response);
                    }
                })
                .catch(() => {
                    if (authenticationStore.setApiError)
                        authenticationStore.setApiError(true);
                });
        }
        callApi();
        return function cleanup() {
            isSubscribed = false;
        };
    }, []);

    const headlineIndreStregStyle = {
        borderBottom: "1px solid #BFBFBF",
        marginBottom: "0",
    };

    const vaelgtTekst = (institutionsnummer: string): string => {
        return institutionsnummer ===
            authenticationStore.aktivInstitution.institutionsnummer
            ? "Denne skole er aktuelt valgt"
            : "";
    };

    const selector = (institutionsnummer: string) => {
        if (
            institutionsnummer ===
            authenticationStore.aktivInstitution.institutionsnummer
        ) {
            return (
                <div className="col" style={{ direction: "rtl" }}>
                    <a
                        href={`auth/stillogin/institution/active/${institutionsnummer}?returnurl=${
                            MenuSetup[Side.indberetning].path
                        }`}
                    >
                        vis
                    </a>
                    <span style={{ marginRight: "14px" }}>
                        {vaelgtTekst(institutionsnummer)}
                    </span>
                </div>
            );
        } else {
            return (
                <div className="col" style={{ direction: "rtl" }}>
                    <a
                        href={`auth/stillogin/institution/active/${institutionsnummer}?returnurl=${
                            MenuSetup[Side.overblik].path
                        }`}
                    >
                        vælg
                    </a>
                    <span style={{ marginRight: "14px" }}>
                        {vaelgtTekst(institutionsnummer)}
                    </span>
                </div>
            );
        }
    };

    return (
        <Page>
            {authenticationStore.authenticatedUser.institutioner.length ===
            0 ? (
                <Redirect to="/ingenadgang" />
            ) : (
                <div className="container">
                    <div className="page-container">
                        <h1>Logget ind</h1>
                        <p>Du er tilknyttet flere skoler.</p>
                        <p>
                            Du må kun bruge Sprogprøver - Indberetning på de
                            skoler, som står på listen over grundskoler der skal
                            gennemføre sprogprøve.
                        </p>
                        <p>
                            Når du har valgt en skole, kan du nemt komme tilbage
                            til denne side og vælge en nye skole. Det gør du ved
                            at klikke på "Vælg skole" i menuen øverst til højre.
                        </p>
                    </div>

                    <h2 style={headlineIndreStregStyle}>Vælg skole</h2>

                    {authenticationStore.authenticatedUser.institutioner.map(
                        (instNr, i) => {
                            const inst = instNr;
                            return (
                                <div
                                    style={{ marginTop: "30px" }}
                                    key={"div1_" + i}
                                >
                                    <div
                                        className="row"
                                        key={"row" + i}
                                        style={headlineIndreStregStyle}
                                    >
                                        <div className="col">
                                            <div
                                                style={{
                                                    marginTop: "0px",
                                                    marginBottom: "16px",
                                                }}
                                            >
                                                <b>
                                                    {inst}
                                                    {inst !== undefined
                                                        ? " "
                                                        : ""}
                                                    ({i}){" "}
                                                    {institutioner.includes(
                                                        inst
                                                    )
                                                        ? " Tilmeldt"
                                                        : ""}
                                                </b>
                                            </div>
                                        </div>

                                        {selector(instNr)}
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            )}
        </Page>
    );
};

export default VaelgSkole;
