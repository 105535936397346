import React, { FunctionComponent, CSSProperties } from 'react';

type OnTilbageClickType = () => void;

type TilbageProps = {
    onClick: OnTilbageClickType,
    text: string
};

const TilbageButtonStyle: CSSProperties = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    outline: 'none'
};

const Tilbage: FunctionComponent<TilbageProps> = (props) => {
    const onChangeEvent = () => {
        props.onClick();
    }

    return (
        <div className="navbar">
            <div className="navbar-context-actions">
                <button style={TilbageButtonStyle}><span className="back-link" onClick={onChangeEvent}>{props.text}</span></button>
            </div>
        </div>
    );
}

export default Tilbage;