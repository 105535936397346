import React, { FunctionComponent, CSSProperties } from 'react';
import { MenuSetup } from '../../helpers/RouteSetup';
import { useLocation } from 'react-router-dom';

type SidenavigationOnClickType = (side: number) => void;

type SidenavigationProps = {
  menuIds: number[]
  onClick: SidenavigationOnClickType,
  navStyle: CSSProperties
};

const LiStyle: CSSProperties = {
  cursor: 'pointer',
  padding: '7px',
};

const Current: CSSProperties = {
  fontWeight: 500,
  fontSize: '1.4rem',
};

const NotCurrent: CSSProperties = {
  fontSize: '1.4rem'
};

const Sidenavigation: FunctionComponent<SidenavigationProps> = (props) => {

  const onClick = (side: number) => {
    props.onClick(side);
  }

  const RenderSidenavigationItems = () => {
    let location = useLocation();

    return props.menuIds.map((side, i) => {
      return (
          <li style={LiStyle} role="menuitem" key={i} className={(location.pathname === MenuSetup[side].path) ? "current" : ""} onClick={() => onClick(side)}>
            <div style={{marginLeft: '10px'}}>
              <span style={(location.pathname === MenuSetup[side].path) ? Current : NotCurrent}>{(i + 1) + ". " + MenuSetup[side].menuTekst}</span>
            </div>
          </li>
      )
    })
  }

  return (
    <nav role="menubar">
      <ul className="sidenav-list" role="menu" style={props.navStyle}>
        {RenderSidenavigationItems()}
      </ul>
    </nav>
  )
}

export default Sidenavigation;