import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Forside from "../pages/Forside";
import Om from "../pages/VaelgSkole";
import LogAf from "../pages/LogAf";
import ProtectedRoute from "./ProtectedRoute";
import LogInd from "../pages/LogInd";
import { Permissions } from "./Permissions";
import Udpegning from "../pages/Udpegning";
import Fejl from "../pages/Fejl";
import Overblik from "../pages/Overblik";
import ErrorBoundary from "./ErrorBoundary";
import Rettigheder from "../pages/Rettigheder";
import { MenuSetup, Side } from "../helpers/RouteSetup";
// import Indberetter from '../pages/Indberetter';
import Indberetning from "../pages/Indberetning";
import SupportHome from "../pages/Support/SupportHome";
import IndberetterElevdata from "../pages/IndberetterElevdata";
import IndberetterTrin3 from "../pages/Indberetter3";
import IndberetterTrin4 from "../pages/Indberetter4";
import IngenAdgang from "../pages/IngenAdgang";
import IndberetningKvittering from "../pages/IndberetningKvittering";
import SupportAntagRolle from "../pages/Support/SupportAntagRolle";
import SupportRsiBrugerdata from "../pages/Support/SupportRsiBrugerdata";
import SupportWsiInst from "../pages/Support/SupportWsiInst";
import { useAuthenticationStore } from "../context/store";
import {
    AktivInstitution,
    AuthenticatedUser,
    emptyUser,
} from "../types/AppTypes";
import ApiService from "../services/ApiService";
// import { Log } from 'oidc-client';

const AppContent: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const [appInitialized, setAppInitialized] = useState<boolean>(false);
    const [userChecked, setUserChecked] = useState<boolean>(false);
    const activeRequest = useRef(false);
    let setSkoleaar = authenticationStore.setSkoleAar;

    // When app updates (user hits F5/refresh) we look for existing cookies
    // [] MUST be present, otherwise useEffect will run twice on eg F5
    useEffect(() => {
        let isSubscribed = true;
        async function callApi() {
            try {
                const response = await ApiService<AuthenticatedUser | "">(
                    "auth/stillogin/authenticateduser"
                );
                if (isSubscribed) {
                    if (response === "") {
                        authenticationStore.setAuthenticatedUser(emptyUser);
                        setAppInitialized(true);
                    } else {
                        authenticationStore.setAuthenticatedUser(response);
                        setAppInitialized(true);
                    }
                    setUserChecked(true);
                }
            } catch (error) {
                if (isSubscribed) {
                    authenticationStore.setUserLoggedIn(false);
                    authenticationStore.setAuthenticatedUser(emptyUser);
                    setAppInitialized(true);
                }
            }
        }
        callApi();
        return function cleanup() {
            isSubscribed = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (authenticationStore.loggedIn() && !activeRequest.current) {
            activeRequest.current = true;
            ApiService<AktivInstitution>("institution/aktiv")
                .then((response) => {
                    if (response === null) {
                        authenticationStore.clearAktivInstitution();
                    } else {
                        authenticationStore.setAktivInstitution(response);
                    }
                    activeRequest.current = false;
                })
                .catch(() => {
                    authenticationStore.clearAktivInstitution();
                    activeRequest.current = false;
                });
        }
    }, [
        authenticationStore.aktivInstitution.institutionsnummer,
        userChecked,
        authenticationStore.authenticatedUser,
    ]);

    useEffect(() => {
        async function callApi() {
            try {
                const response = await ApiService<number>(
                    "institution/hentskoleaar"
                );
                setSkoleaar(response);
            } catch (err) {
                console.log(err);
            }
        }
        if (appInitialized) {
            callApi();
        }
    }, [authenticationStore.userLoggedIn, setSkoleaar, appInitialized]);

    if (!appInitialized) {
        return <p>Loading..</p>;
    } else {
        return (
            <BrowserRouter>
                <ErrorBoundary>
                    <Switch>
                        {/* Support sider */}
                        <ProtectedRoute
                            path={MenuSetup[Side.supportRsiBrugerdata].path}
                            requiredPermissions={Permissions.support}
                            component={() => <SupportRsiBrugerdata />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.supportWsiInst].path}
                            requiredPermissions={Permissions.support}
                            component={() => <SupportWsiInst />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.supportAntagRolle].path}
                            requiredPermissions={Permissions.support}
                            component={() => <SupportAntagRolle />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.support].path}
                            requiredPermissions={Permissions.support}
                            component={() => <SupportHome />}
                        />

                        {/* Sprogprøver sider */}
                        <Route
                            path={MenuSetup[Side.vaelgSkole].path}
                            component={() => <Om />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.udpegning].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => <Udpegning />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.indberetning].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => <Indberetning />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.indberetterTrin1].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => (
                                <IndberetterElevdata klassetrin0={true} />
                            )}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.indberetterTrin2].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => (
                                <IndberetterElevdata klassetrin0={false} />
                            )}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.indberetterTrin3].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => <IndberetterTrin3 />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.indberetterTrin4].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => <IndberetterTrin4 />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.indberetningKvittering].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => <IndberetningKvittering />}
                        />
                        <ProtectedRoute
                            path={MenuSetup[Side.overblik].path}
                            requiredPermissions={
                                Permissions.institutionsledelse
                            }
                            component={() => <Overblik />}
                        />
                        <Route
                            path={MenuSetup[Side.logind].path}
                            component={() => <LogInd />}
                        />
                        <Route
                            path={MenuSetup[Side.rettigheder].path}
                            component={() => <Rettigheder />}
                        />
                        <Route
                            path={MenuSetup[Side.logaf].path}
                            component={() => <LogAf />}
                        />
                        <Route
                            path={MenuSetup[Side.fejl].path}
                            component={() => <Fejl />}
                        />
                        <Route
                            path={MenuSetup[Side.ingenAdgang].path}
                            component={() => <IngenAdgang />}
                        />
                        <Route
                            path={MenuSetup[Side.root].path}
                            component={() => <Forside />}
                        />
                    </Switch>
                </ErrorBoundary>
            </BrowserRouter>
        );
    }
};

export default AppContent;
