import React, { FunctionComponent, CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type OnFunktionsLinkClickType = () => void;

type FunktionsLinkProps = {
    onClick: OnFunktionsLinkClickType,
    text: string,
    icon: IconProp
};

const FunktionsLinkButtonStyle: CSSProperties = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    outline: 'none'
};

const FunktionsLink: FunctionComponent<FunktionsLinkProps> = (props) => {
    const onChangeEvent = () => {
        props.onClick();
    }

    return (
            <button style={FunktionsLinkButtonStyle}>
                <FontAwesomeIcon style={{marginRight: 4}} icon={props.icon} onClick={onChangeEvent} />
                <span onClick={onChangeEvent} style={{borderBottom: "1px solid #747474"}}>{props.text}</span>
            </button>
    );
}

export default FunktionsLink;