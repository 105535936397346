export const Side = {
    root: 0,
    vaelgSkole: 1,
    overblik: 2,
    logind: 3,
    logaf: 4,
    fejl: 5,
    ingenAdgang: 6,
    rettigheder: 7,
    information: 8,
    support: 9,
    supportAntagRolle: 10,
    supportRsiBrugerdata: 11,
    supportWsiInst: 12,
    udpegning: 13,
    indberetning: 14,
    indberetterTrin1: 15,
    indberetterTrin2: 16,
    indberetterTrin3: 17,
    indberetterTrin4: 18,
    indberetningKvittering: 19,
    silent: 20,
};

type MenuItem = {
    path: string;
    menuTekst: string;
};

export const MenuSetup: MenuItem[] = [];

const setupMenu = () => {
    MenuSetup[Side.root] = { path: "/", menuTekst: "Forside" };
    MenuSetup[Side.vaelgSkole] = {
        path: "/vaelgskole",
        menuTekst: "Vælg skole",
    };
    MenuSetup[Side.overblik] = {
        path: "/indberet",
        menuTekst: "Registrering og indberetning",
    };
    MenuSetup[Side.udpegning] = {
        path: "/indberet/udpegning",
        menuTekst: "Vælg elever der skal til sprogprøve",
    };
    MenuSetup[Side.indberetning] = {
        path: "/indberet/indberetning",
        menuTekst: "-",
    };
    MenuSetup[Side.indberetterTrin1] = {
        path: "/indberet/indberetter/trin1",
        menuTekst: "Elever i børnehaveklasse",
    };
    MenuSetup[Side.indberetterTrin2] = {
        path: "/indberet/indberetter/trin2",
        menuTekst: "Elever i 1. - 9. klasse",
    };
    MenuSetup[Side.indberetterTrin3] = {
        path: "/indberet/indberetter/trin3",
        menuTekst: "Fritagne elever",
    };
    MenuSetup[Side.indberetterTrin4] = {
        path: "/indberet/indberetter/trin4",
        menuTekst: "Indberetning",
    };
    MenuSetup[Side.indberetningKvittering] = {
        path: "/indberet/indberetter/kvittering",
        menuTekst: "Kvittering",
    };
    MenuSetup[Side.logind] = { path: "/logind", menuTekst: "-" };
    MenuSetup[Side.logaf] = { path: "/logaf", menuTekst: "-" };
    MenuSetup[Side.fejl] = { path: "/fejl", menuTekst: "-" };
    MenuSetup[Side.ingenAdgang] = { path: "/ingenadgang", menuTekst: "-" };
    MenuSetup[Side.rettigheder] = { path: "/rettigheder", menuTekst: "-" };
    MenuSetup[Side.information] = {
        path: "/information",
        menuTekst: "Information og vejledning",
    };

    //Support sider
    MenuSetup[Side.support] = { path: "/support", menuTekst: "Support" };
    MenuSetup[Side.supportAntagRolle] = {
        path: "/support/antagrolle",
        menuTekst: "Antag rolle",
    };
    MenuSetup[Side.supportRsiBrugerdata] = {
        path: "/support/rsibrugerdata",
        menuTekst: "rsiBrugerdata",
    };
    MenuSetup[Side.supportWsiInst] = {
        path: "/support/wsiinst",
        menuTekst: "wsiInst",
    };
};

export const findMenuItem = (path: string): MenuItem | undefined => {
    const item = MenuSetup.find((i) => i.path === path);
    return item;
};

setupMenu();
