import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom'

// Called upon OIDC logut
const LogAf: FunctionComponent = () => {
    return (
        <Redirect to='/' />
    );
}

export default LogAf;
