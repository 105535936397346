import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer: FunctionComponent = () => {
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="align-text-left">
                        <ul className="unstyled-list inline-list">
                            <li className=""><span
                                className="h5 weight-semibold">Styrelsen for It og Læring</span>
                            </li>
                            <li className=""><a href='https://viden.stil.dk/pages/viewpage.action?pageId=61865993'
                                className="function-link icon-link"
                                title="Support">Support<FontAwesomeIcon icon={['fas', 'external-link-alt']} className="ml-2 fa-xs" aria-hidden='true' focusable='false' /></a>
                            </li>
                            <li className=""><a href="https://www.uvm.dk/folkeskolen/laering-og-laeringsmiljoe/sprogproever/regler-og-vejledning"
                                className="function-link icon-link"
                                title="Privatlivspolitik og cookies">Regler og vejledning<FontAwesomeIcon icon={['fas', 'external-link-alt']} className="ml-2 fa-xs" aria-hidden='true' focusable='false' /></a></li>
                            <li className="d-print-none"><a href="https://www.was.digst.dk/indberet-sprogpr%C3%B8ver-dk"
                                className="function-link icon-link"
                                title="Tilgængelighedserklæring">Tilgængelighedserklæring<FontAwesomeIcon icon={['fas', 'external-link-alt']} className="ml-2 fa-xs" aria-hidden='true' focusable='false' /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
