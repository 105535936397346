import React, { FunctionComponent, useState } from "react";
import SupportPage from "./SupportPage";
import ApiService from "../../services/ApiService";

type BrugerInfoDto = {
    Unilogin: string;
};

type Gruppe = {
    instnr: string;
    gruppeid: string;
    gruppenavn: string;
    gruppetype: number;
    gruppetrin: number;
    gruppetrinSpecified: boolean;
    fraDato: string;
    fraDatoSpecified: boolean;
    tilDato: string;
    tilDatoSpecified: boolean;
};

type Tjeneste = {
    udbydernr: string;
    serienavn: string;
    seriekode: string;
    tjenestekode: string;
    tjenestenavn: string;
    url: string;
    matplatid: string;
};

type Licens = {
    tjeneste: Tjeneste;
    fraDato: string;
    fraDatoSpecified: boolean;
    tilDato: string;
    tilDatoSpecified: boolean;
};

type Item = {
    rolle: number[];
    initialer: string;
};

type Bruger = {
    instnr: string;
    brugerid: string;
    navn: string;
    item: Item;
    gruppe: Gruppe[];
};

type GruppeLicens = {
    gruppe: Gruppe;
    licens: Licens;
};

type WsiInstDto = {
    instBrugere: Bruger[];
    gruppeLicenser: Array<GruppeLicens[]>;
};

const SupportWsiInst: FunctionComponent = () => {
    const [unilogin, setUnilogin] = useState<string>("");
    const [info, setInfo] = useState<WsiInstDto | undefined>(undefined);
    const [fejl, setFejl] = useState<string>("");

    const HentInfo = () => {
        setInfo(undefined);
        setFejl("");

        const bruger: BrugerInfoDto = {
            Unilogin: unilogin,
        };

        ApiService<WsiInstDto>("support/wsiinst", false, "post", bruger)
            .then((response) => {
                if (response) {
                    setInfo(response);
                }
            })
            .catch((e: Error) => {
                setFejl(e.message);
                console.log(e);
            });
    };

    const renderGrupper = (grupper: Gruppe[]) => {
        const grp = Array<object>();
        grupper.forEach((item, key) =>
            grp.push(
                <tr key={"tr" + key}>
                    <td>{item.instnr}</td>
                    <td>{item.gruppenavn}</td>
                    <td>{item.gruppeid}</td>
                    <td>{item.gruppetype}</td>
                    <td>{item.gruppetrinSpecified ? item.gruppetrin : "-"}</td>
                    <td>{item.fraDatoSpecified ? item.fraDato : "-"}</td>
                    <td>{item.tilDatoSpecified ? item.tilDato : "-"}</td>
                </tr>
            )
        );
        return grp;
    };

    const renderGruppeLicenser = (gl: GruppeLicens[]) => {
        const grp = Array<object>();

        gl.forEach((item, key) => {
            grp.push(
                <div className="table--responsive-scroll" key={"div" + key}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Gruppe</th>
                                <th>Licens</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    instnr: {item.gruppe.instnr} <br />
                                    gruppeid: {item.gruppe.gruppeid} <br />
                                    gruppenavn: {item.gruppe.gruppenavn} <br />
                                    gruppetype: {item.gruppe.gruppetype} <br />
                                    gruppetrin:{" "}
                                    {item.gruppe.gruppetrinSpecified
                                        ? item.gruppe.gruppetrin
                                        : "-"}{" "}
                                    <br />
                                    fraDato:{" "}
                                    {item.gruppe.fraDatoSpecified
                                        ? item.gruppe.fraDato
                                        : "-"}{" "}
                                    <br />
                                    tilDato:{" "}
                                    {item.gruppe.tilDatoSpecified
                                        ? item.gruppe.tilDato
                                        : "-"}{" "}
                                    <br />
                                </td>
                                <td>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="header-title">
                                                Tjeneste
                                            </h3>
                                        </div>
                                        <div className="card-text">
                                            udbydernr:{" "}
                                            {item.licens.tjeneste.udbydernr}{" "}
                                            <br />
                                            serienavn:{" "}
                                            {
                                                item.licens.tjeneste.serienavn
                                            }{" "}
                                            <br />
                                            seriekode:{" "}
                                            {
                                                item.licens.tjeneste.seriekode
                                            }{" "}
                                            <br />
                                            tjenestenavn:{" "}
                                            {
                                                item.licens.tjeneste
                                                    .tjenestenavn
                                            }{" "}
                                            <br />
                                            tjenestekode:{" "}
                                            {
                                                item.licens.tjeneste
                                                    .tjenestekode
                                            }{" "}
                                            <br />
                                            url: {item.licens.tjeneste.url}{" "}
                                            <br />
                                            matplatid:{" "}
                                            {
                                                item.licens.tjeneste.matplatid
                                            }{" "}
                                            <br />
                                        </div>
                                    </div>
                                    fraDato:{" "}
                                    {item.licens.fraDatoSpecified
                                        ? item.licens.fraDato
                                        : "-"}{" "}
                                    <br />
                                    tilDato:{" "}
                                    {item.licens.tilDatoSpecified
                                        ? item.licens.tilDato
                                        : "-"}{" "}
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        });

        return grp;
    };

    const renderBrugere = () => {
        const brugere = Array<object>();
        info?.instBrugere?.forEach((item, key) => {
            brugere.push(
                <div key={"d" + key}>
                    <div className="table--responsive-scroll" key={key}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Institution</th>
                                    <th>Navn</th>
                                    <th>Item</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{item.instnr}</td>
                                    <td>{item.navn}</td>
                                    <td>
                                        {item.item
                                            ? JSON.stringify(item.item)
                                            : "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        {item.gruppe &&
                                            item.gruppe.length > 0 && (
                                                <div>
                                                    <h3>Grupper</h3>
                                                    <div
                                                        className="table--responsive-scroll"
                                                        key={"tab" + key}
                                                    >
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Instnr
                                                                    </th>
                                                                    <th>
                                                                        Navn
                                                                    </th>
                                                                    <th>Id</th>
                                                                    <th>
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Trin
                                                                    </th>
                                                                    <th>
                                                                        Fra dato
                                                                    </th>
                                                                    <th>
                                                                        Til dato
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {renderGrupper(
                                                                    item.gruppe
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                </div>
            );
        });
        return <div>{brugere}</div>;
    };

    return (
        <SupportPage>
            <h1>Hent brugerinformation fra wsiInst</h1>
            <div>
                Hvorvidt en bruger har licens til anvendelse af Sprogprøver
                bestemmes ved hjælp af wsiINST.
                <br />
                Vi kigger efter "tjenestekode: sprogproever"
            </div>
            <p>
                <label style={{ paddingRight: 20 }}>
                    Unilogin: &nbsp;
                    <input
                        type="text"
                        onChange={(e) => setUnilogin(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && unilogin.length > 0)
                                HentInfo();
                        }}
                    />
                </label>
                <button
                    disabled={unilogin.length === 0}
                    className="button button-primary"
                    onClick={() => HentInfo()}
                >
                    Hent info
                </button>
            </p>

            {fejl.length > 0 && (
                <div className="alert alert-error" role="alert">
                    <div className="alert-body">
                        <p className="alert-heading">Fejl</p>
                        <p className="alert-text">{fejl}</p>
                    </div>
                </div>
            )}

            {info && (
                <div>
                    <h2>
                        Bruger tilknytning for:{" "}
                        {info.instBrugere && info.instBrugere.length > 0
                            ? info.instBrugere[0].brugerid
                            : "ikke fundet"}
                    </h2>
                    {renderBrugere()}
                </div>
            )}
        </SupportPage>
    );
};

export default SupportWsiInst;
