import axios from 'axios';
import { MessageTemplate } from './utils/MessageTemplate';
//import config from "../context/ClientConfiguration"; // For dynamic config loggingendpoint

type ClientLogging = {
    messageTemplate: string, 
    properties: string
}

export enum LogEventLevel {
    debug = "debug",
    information = "information",
    warning = "warning",
    error = "error",
    critical = "critical",
    trace = "trace"
}

const path: string = "clientlogging/";

const post = async (level: string, messageTemplate: string, properties: string) => {
    //const rcc = await config.getData();
    const logging: ClientLogging = { messageTemplate: messageTemplate, properties: properties };
    //axios.post(`${rcc.loggingEndpoint}/${path}${level}`, logging);
    axios.post(`/${path}${level}`, logging);
}
export const LogDebug = (errorOrMessageTemplate: Error | string, ...properties: any[]) => {
    try {
        if (errorOrMessageTemplate instanceof Error) {
            Write(LogEventLevel.debug, properties[0], properties.slice(1), errorOrMessageTemplate);
        } else {
            Write(LogEventLevel.debug, errorOrMessageTemplate, properties);
        }
    } catch (error) {
        console.log(error);
    }
};

export const LogInformation = (errorOrMessageTemplate: Error | string, ...properties: any[]) => {
    try {
        if (errorOrMessageTemplate instanceof Error) {
            Write(LogEventLevel.information, properties[0], properties.slice(1), errorOrMessageTemplate);
        } else {
            Write(LogEventLevel.information, errorOrMessageTemplate, properties);
        }
    } catch (error) {
        console.log(error);
    }
};

export const LogWarning = (errorOrMessageTemplate: Error | string, ...properties: any[]) => {
    try {
        if (errorOrMessageTemplate instanceof Error) {
            Write(LogEventLevel.warning, properties[0], properties.slice(1), errorOrMessageTemplate);
        } else {
            Write(LogEventLevel.warning, errorOrMessageTemplate, properties);
        }
    } catch (error) {
        console.log(error);
    }
};

export const LogError = (errorOrMessageTemplate: Error | string, ...properties: any[]) => {
    try {
        if (errorOrMessageTemplate instanceof Error) {
            Write(LogEventLevel.error, properties[0], properties.slice(1), errorOrMessageTemplate);
        } else {
            Write(LogEventLevel.error, errorOrMessageTemplate, properties);
        }
    } catch (error) {
        console.log(error);
    }
};

export const LogCritical = (errorOrMessageTemplate: Error | string, ...properties: any[]) => {
    try {
        if (errorOrMessageTemplate instanceof Error) {
            Write(LogEventLevel.critical, properties[0], properties.slice(1), errorOrMessageTemplate);
        } else {
            Write(LogEventLevel.critical, errorOrMessageTemplate, properties);
        }
    } catch (error) {
        console.log(error);
    }
};

export const LogTrace = (errorOrMessageTemplate: Error | string, ...properties: any[]) => {
    try {
        if (errorOrMessageTemplate instanceof Error) {
            Write(LogEventLevel.trace, properties[0], properties.slice(1), errorOrMessageTemplate);
        } else {
            Write(LogEventLevel.trace, errorOrMessageTemplate, properties);
        }
    } catch (error) {
        console.log(error);
    }
};



const Write = (level: LogEventLevel, rawMessageTemplate: string, unboundProperties: any[], error?: Error) => {
    const messageTemplate = new MessageTemplate(rawMessageTemplate);
    const properties = messageTemplate.bindProperties(unboundProperties);
    post(level, rawMessageTemplate, JSON.stringify(properties));
}
