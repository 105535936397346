import axios from "axios";
import { UserManager } from "oidc-client";
import { LogError } from "./ErrorService";
import { useAuthenticationStore } from "../context/store";

const fileDownloadService = <T>(url: string): Promise<T> => {
    const callApi = async (): Promise<any> => {
        return _callApi().catch((error) => {
            LogError(error.stack ? error.stack : error.message);
            throw error;
        });
    };
    const finalUrl =
        url.startsWith("http:") || url.startsWith("https:") ? url : `/${url}`;
    const _callApi = async () => {
        try {
            await axios({
                url: finalUrl,
                method: "GET",
                responseType: "blob",
                withCredentials: true,
            }).then((response) => {
                console.log(JSON.stringify(response));
                let fileName = response.headers["filename"];
                let contentType = response.headers["content-type"];

                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: contentType })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } catch (err) {
            throw err;
        }
    };

    return callApi();
};

export default fileDownloadService;
