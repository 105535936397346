import React, { FunctionComponent } from "react";
import SupportPage from "./SupportPage";

const SupportAntagRolle: FunctionComponent = () => {
    return (
        <SupportPage>
            <h1>Antag rolle på institution</h1>
            <p>
                Denne funktionalitet virker ikke længere grundet udfasning af
                ClaimsService. <br />
                Brug istedet TOPS infoninjaen
            </p>
        </SupportPage>
    );
};

export default SupportAntagRolle;
