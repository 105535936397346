import React, { FunctionComponent } from "react";
import Header from "../../components/Header";
import "./Support.css";
import { useHistory } from "react-router-dom";
import { MenuSetup, Side } from "../../helpers/RouteSetup";
import ProtectedComponent from "../../components/ProtectedComponent";
import { Permissions } from "../../components/Permissions";
import Sidenavigation from "../../components/common/Sidenavigation";

type PageProps = {};

const SupportPage: FunctionComponent<PageProps> = (props) => {
    const history = useHistory();

    return (
        <div>
            <Header navigation={false} loginButton={true} />
            <div className="left">
                <ProtectedComponent
                    requiredPermissions={Permissions.support}
                    requireLicence={false}
                >
                    <Sidenavigation
                        menuIds={[
                            Side.supportAntagRolle,
                            Side.supportRsiBrugerdata,
                            Side.supportWsiInst,
                        ]}
                        onClick={(side) => history.push(MenuSetup[side].path)}
                        navStyle={{ width: "140px" }}
                    ></Sidenavigation>
                </ProtectedComponent>
            </div>

            <div className="main">{props.children}</div>
        </div>
    );
};

export default SupportPage;
