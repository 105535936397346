import React, { ReactElement } from "react";

enum alignmentEnum {
    "left",
    "right",
}

type ContentType = string | number | ReactElement;

type CellPropType = {
    style: Object;
    orderBy: any;
    content: ContentType;
};

type CellType = ContentType | CellPropType;

type RowPropType = {
    className: string;
    cells: CellType[];
};

type HeaderType = string | ReactElement;

type TableProps = {
    id: string;
    className: string;
    headers: HeaderType[];
    dataLabels: string[];
    widths: string[];
    alignments: alignmentEnum[];
    wordBreaks: string[];
    rows: RowPropType[];
    footerRow?: RowPropType;
    footer: string | ReactElement;
};

// sorters is an array [f1, f2, null, null, f3] of sort-functions.
// defaultSort is a object {column:int, asc: bool}
class Table extends React.Component<TableProps, any> {
    render() {
        const {
            headers,
            widths,
            wordBreaks,
            footerRow,
            footer,
            rows,
            className,
            alignments,
        } = this.props;
        let dataLabels = this.props.dataLabels || headers;
        const styleForColumn = (i: number) => {
            let style: { [key: string]: string | number } = {};
            if (widths != null && i < widths.length) {
                style["width"] = widths[i];
            }
            if (alignments != null && i < alignments.length) {
                style["text-align"] = alignments[i];
            }
            if (wordBreaks != null && i < wordBreaks.length) {
                style["wordBreak"] = wordBreaks[i];
            }
            return style;
        };
        const headersHTML = headers.map((header: HeaderType, i: number) => {
            if (header === "") return <td key={i}></td>;
            var style = { ...styleForColumn(i) };
            style["verticalAlign"] = "bottom";
            let content = header;
            return (
                <th key={i} tabIndex={-1} style={style}>
                    {content}
                </th>
            );
        });

        const isCellPropType = (cell: any) => {
            return (
                cell.style !== undefined &&
                cell.orderBy !== undefined &&
                cell.content !== undefined
            );
        };

        const renderTD = (column: number, cell: CellType) => {
            const dataLabel = dataLabels[column];
            var content: ContentType;
            var style = styleForColumn(column);
            if (isCellPropType(cell)) {
                const currentCell = cell as CellPropType;
                content = currentCell.content;
                if (currentCell.style != null) {
                    Object.assign(style, currentCell.style);
                }
            } else {
                content = cell as ContentType;
            }
            return (
                <td style={style} key={column} data-label={dataLabel}>
                    {content || "\u00a0"}
                </td>
            );
        };

        const rowsHTML = rows.map((row: RowPropType, i: number) => {
            var cellsHTML = row.cells.map((cell: CellType, j: number) => {
                return renderTD(j, cell);
            });
            return (
                <tr className={row.className} key={i}>
                    {cellsHTML}
                </tr>
            );
        });

        const footerHTML: Object[] = [];
        if (footerRow) {
            const cellsHTML = footerRow.cells.map((cell, j) => {
                return renderTD(j, cell);
            });
            footerHTML.push(
                <tr className={footerRow.className} key="footer-row">
                    {cellsHTML}
                </tr>
            );
        }

        if (footer) {
            footerHTML.push(
                <tr key="footer-row" className="white-row">
                    <td colSpan={headers.length}>
                        {footer}
                        &nbsp;
                    </td>
                </tr>
            );
        }
        return (
            <table className={"table " + (className || "")}>
                <thead>
                    <tr>{headersHTML}</tr>
                </thead>
                <tbody>{rowsHTML}</tbody>
                <tfoot>{footerHTML}</tfoot>
            </table>
        );
    }
}

export default Table;
