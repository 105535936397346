import React, { FunctionComponent, useState, ChangeEvent } from 'react';

type RadioProps = {
    id: string,
    changed: (event: ChangeEvent<HTMLInputElement>) => void,
    value: string,
    label: string,
    isSelected: boolean,
    groupClassName?: string,
    className?: string
};

const RadioButton: FunctionComponent<RadioProps> = (props) => {
    return (
            <div className={props.groupClassName + ' mb-3'}>
                <input id={props.id} onChange={props.changed} value={props.value} type="radio" checked={props.isSelected} className={props.className + ' form-radio radio-large'}  aria-label="Radiobutton"/>
                <label htmlFor={props.id}>{props.label}</label>
            </div>
    );
}

type OnRadioChangePropType = (id: string, value: string) => void;

type RadioListProps = {
    options: Array<number | string>,
    selected: number | string,
    selectid: string,
    groupClassName?: string,
    buttonClassName?: string,
    onChangeProp?: OnRadioChangePropType
};

const Radioknapper: FunctionComponent<RadioListProps> = (props) => {
    const [selected, setSelected] = useState<string>(String(props.selected));

    function onChangeEvent(event) {
        var value = event.target.value; 
        if (props.onChangeProp) {
            props.onChangeProp(props.selectid, value);
        }
        setSelected(value);
    }

    var options = props.options.map((n: number | string) => {
        return <RadioButton key={props.selectid + '_' + n} id={props.selectid + '_' + n} 
            isSelected={String(n) === selected} label={String(n)} value={String(n)} 
            changed={(e) => onChangeEvent(e)} className={props.buttonClassName} groupClassName={props.groupClassName} />;
    })
    return <div id={props.selectid}>
            {options}
        </div>;
};

export default Radioknapper;