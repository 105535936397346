import React, {FunctionComponent} from 'react';

type StatusbadgeProps = {
    id: string,
    label: string,
    className: string
};

const statusbadgeStyle = {
    width: '150px',
    margin: '5px 0px 5px 0px',
    fontSize: '13px',
    textAlign: 'left' as 'left'
}

const Statusbadge: FunctionComponent<StatusbadgeProps> = (props) => {
    return (
    <label className={'badge ' + props.className} style={statusbadgeStyle}>{props.label}</label>
    );
}

export default Statusbadge;