import React, { FunctionComponent } from 'react';
import Page from '../components/Page';

const IngenAdgang: FunctionComponent = (props) => {

    return (
        <Page>
            <div className="container">
                <h2>Du har ikke adgang til Sprogprøver.dk</h2>

                <div className="alert alert-info " role="alert"
                    aria-label="Beskedbox der viser information">
                    <div className="alert-body">
                        <p className="alert-heading ">Der kan være forskellige grunde til, at du ikke har adgang:</p>
                        <div className="alert-text">
                            <ul>
                                <li>Du er ikke tilknyttet en skole, der er omfattet af kravet om sprogprøver.<br />
                                    <a href="https://www.uvm.dk/folkeskolen/laering-og-laeringsmiljoe/sprogproever/gennemfoerelse">Se listen over skoler der skal aflægge sprogprøve</a>
                                </li>
                                <li>Du har ikke en rolle, der giver dig adgang til Sprogprøver.dk. Kun brugere med rollen 'Leder', 'Ledelse' eller 'TAP' kan indberette.<br />
                                        Kontakt din Unilogin brugeradministrator, hvis du mener, at du bør have adgang til Sprogprøver.dk.<br /><br />
                                        Bemærk: Hvis I laver ændringer i skolens administrative system, går der som regel et døgn, før de slår igennem i andre systemer herunder Sprogprøver.dk.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default IngenAdgang;
