import React, { FunctionComponent, useState } from "react";
import SupportPage from "./SupportPage";
import ApiService from "../../services/ApiService";

type BrugerInfoRequestDto = {
    Unilogin: string;
};

type Gruppe = {
    gruppekode: string;
    navn: string;
    gruppetype: string;
    oprettet_ts: number;
    aendret_ts: number;
};

type Bruger = {
    fornavn: string;
    efternavn: string;
    roller: string[];
    institution_ref: string;
    uni_id: string;
    oprettet_ts: number;
    aendret_ts: number;
    grupper: Gruppe[];
};

type BrugerInfoDto = {
    medarbejdere: Bruger[];
};

const SupportRsiBrugerdata: FunctionComponent = () => {
    const [unilogin, setUnilogin] = useState<string>("");
    const [info, setInfo] = useState<BrugerInfoDto | undefined>(undefined);
    const [fejl, setFejl] = useState<string>("");

    const HentInfo = () => {
        setInfo(undefined);
        setFejl("");

        const bruger: BrugerInfoRequestDto = {
            Unilogin: unilogin,
        };

        ApiService<BrugerInfoDto>(
            "support/rsibrugerdata",
            false,
            "post",
            bruger
        )
            .then((response) => {
                if (response) {
                    setInfo(response);
                }
            })
            .catch((e: Error) => {
                setFejl(e.message);
                console.log(e);
            });
    };

    const timeToString = (time: number): string => {
        let d = new Date(Number(time));
        return d.toLocaleString();
    };

    const renderGrupper = (grupper: Gruppe[]) => {
        const grp = Array<object>();
        grupper.forEach((item, key) =>
            grp.push(
                <tr key={"tr" + key}>
                    <td>{item.navn}</td>
                    <td>{item.gruppetype}</td>
                    <td>{item.gruppekode}</td>
                    <td>{timeToString(item.oprettet_ts)}</td>
                    <td>{timeToString(item.aendret_ts)}</td>
                </tr>
            )
        );

        return grp;
    };

    const renderBrugere = () => {
        const brugere = Array<object>();
        info?.medarbejdere.forEach((item, key) =>
            brugere.push(
                <div key={"d" + key}>
                    <div className="table--responsive-scroll" key={key}>
                        <table className="table" key={"t" + key}>
                            <thead>
                                <tr>
                                    <th>Institution</th>
                                    <th>Fornavn</th>
                                    <th>Efternavn</th>
                                    <th>Oprettet</th>
                                    <th>Ændret</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{item.institution_ref}</td>
                                    <td>{item.fornavn}</td>
                                    <td>{item.efternavn}</td>
                                    <td>{timeToString(item.oprettet_ts)}</td>
                                    <td>{timeToString(item.aendret_ts)}</td>
                                </tr>
                                {item.grupper.length > 0 && (
                                    <tr key={"trg" + key}>
                                        <td colSpan={5}>
                                            <h3>Grupper</h3>
                                            <div
                                                className="table--responsive-scroll"
                                                key={"tab" + key}
                                            >
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Navn</th>
                                                            <th>Gruppetype</th>
                                                            <th>Gruppekode</th>
                                                            <th>Oprettet</th>
                                                            <th>Ændret</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {renderGrupper(
                                                            item.grupper
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <br />
                </div>
            )
        );
        return <div>{brugere}</div>;
    };

    return (
        <SupportPage>
            <h1>Hent brugerinformation fra rsiBRUGERDATA</h1>
            <div>
                En brugers roller på institutioner findes ved hjælp af
                rsiBRUGERDATA.
            </div>
            <p>
                <label style={{ paddingRight: 20 }}>
                    Unilogin: &nbsp;
                    <input
                        type="text"
                        onChange={(e) => setUnilogin(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && unilogin.length > 0)
                                HentInfo();
                        }}
                    />
                </label>
                <button
                    disabled={unilogin.length === 0}
                    className="button button-primary"
                    onClick={() => HentInfo()}
                >
                    Hent info
                </button>
            </p>

            {fejl.length > 0 && (
                <div className="alert alert-error" role="alert">
                    <div className="alert-body">
                        <p className="alert-heading">Fejl</p>
                        <p className="alert-text">{fejl}</p>
                    </div>
                </div>
            )}

            {info && (
                <div>
                    <h2>
                        Medarbejderaktør fra Elevdatabasen:{" "}
                        {info.medarbejdere && info.medarbejdere.length > 0
                            ? info.medarbejdere[0].uni_id
                            : "ikke fundet"}
                    </h2>
                    {renderBrugere()}
                </div>
            )}
        </SupportPage>
    );
};

export default SupportRsiBrugerdata;
