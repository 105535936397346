import React, { FunctionComponent, useState, useEffect } from "react";
import "../assets/stylesheets/Indberetning.css";
import ApiService from "../services/ApiService";
import PageLoader from "../components/PageLoader";
import Page from "../components/Page";
import Sidenavigation from "../components/common/Sidenavigation";
import { Side, MenuSetup } from "../helpers/RouteSetup";
import { useHistory } from "react-router-dom";
import Tilbage from "../components/common/Tilbage";
import { useAuthenticationStore } from "../context/store";

const IndberetterTrin3: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const skoleaar = authenticationStore.getSkoleAar();
    const [antalFritagede, setAntalFritagede] = useState<number | undefined>(
        undefined
    );
    const [newAntalFritagede, setNewAntalFritagede] = useState<
        number | undefined
    >(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [validInput, setValidInput] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        async function callApi() {
            ApiService<number>(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/hentindberetning/" +
                    skoleaar +
                    "/fritagede"
            )
                .then((response) => {
                    setAntalFritagede(response);
                    setNewAntalFritagede(response);
                })
                .catch((e) => {
                    setError(e);
                });
        }
        callApi();
    }, [
        authenticationStore.authenticatedUser,
        authenticationStore.aktivInstitution,
        skoleaar,
    ]);

    function onChange(e) {
        var input = e.target.value;
        setNewAntalFritagede(input);
        setValidInput(
            input !== undefined && input.length <= 4 && /^[0-9]+$/.test(input)
        );
    }

    const next = (side: number) => {
        if (validInput) {
            ApiService<boolean>(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/indberetning/" +
                    skoleaar +
                    "/trin/3",
                false,
                "post",
                Object(Number(newAntalFritagede))
            )
                .then(() => {
                    history.push(MenuSetup[side].path);
                })
                .catch((e) => {
                    setError(e);
                });
        }
    };

    const Indberetter3 = () => {
        const FritagneEleverInputError = () => {
            if (!validInput) {
                return (
                    <div className="col mt-2" key="error-col">
                        <div className="form-group form-error">
                            <span
                                className="form-error-message"
                                id="form-error-error"
                            >
                                <span className="sr-only">Fejl:</span> Ugyldigt
                                input. Antallet skal være mellem 0 og 9999.
                            </span>
                        </div>
                    </div>
                );
            }
            return null;
        };

        return (
            <div>
                <p className="h6 m-0">INDBERET SKOLENS RESULTATER</p>
                <h1>Fritagne elever</h1>
                <div>
                    <p>
                        I henhold til § 14 i bekendtgørelse om obligatoriske
                        sprogprøver i grundskolen, skal skolens leder fritage en
                        elev fra sprogprøven, hvis eleven på grund af betydelig
                        funktionsnedsættelse heller ikke på særlige vilkår kan
                        aflægge prøven.
                    </p>
                    <p>
                        Det er det samlede antal fritagne elever på tværs af
                        sprogprøven i børnehaveklassen og 1.-9. klasse, som skal
                        indberettes.
                    </p>
                    <h3>Antal fritagne elever</h3>
                    <div className="row" key="input-row">
                        <div className="col col-sm-2" key="input-col">
                            <input
                                key="fritagne-input2"
                                className="form-input"
                                type="tel"
                                maxLength={4}
                                title="Indskriv antal fritagne elever"
                                value={newAntalFritagede}
                                onChange={(e) => onChange(e)}
                                style={{ width: "75px" }}
                                aria-label="Input fritagne elever"
                            />
                        </div>
                        <FritagneEleverInputError />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <PageLoader dataPresent={antalFritagede} error={error}>
            <Page>
                <div className="container page-container sp-indb-container">
                    <div className="sidenav sp-indb-sidenav">
                        <Sidenavigation
                            menuIds={[
                                Side.indberetterTrin1,
                                Side.indberetterTrin2,
                                Side.indberetterTrin3,
                                Side.indberetterTrin4,
                            ]}
                            onClick={(side) => next(side)}
                            navStyle={{ width: "240px" }}
                        ></Sidenavigation>
                    </div>
                    <div className="content sp-indb-content">
                        {Indberetter3()}
                        <button
                            className="button button-primary mt-5"
                            onClick={() => next(Side.indberetterTrin4)}
                        >
                            Næste
                        </button>
                        <div className="navbar-inner navbar-context-actions">
                            <Tilbage
                                text="Tilbage"
                                onClick={() => next(Side.indberetterTrin2)}
                            />
                        </div>
                    </div>
                </div>
            </Page>
        </PageLoader>
    );
};

export default IndberetterTrin3;
