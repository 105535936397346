import React, { Fragment, PropsWithChildren } from "react";
import { useAuthenticationStore } from "../context/store";
import AppPermissions, { Permissions } from "./Permissions";

type ProtectedComponentProps = {
    requireLicence?: boolean;
    requiredPermissions: Permissions;
};

const ProtectedComponent = ({
    requireLicence,
    requiredPermissions,
    children,
}: PropsWithChildren<ProtectedComponentProps>) => {
    const ignoreProtectedComponent: boolean = false;
    const requiredRoles: string[] = requiredPermissions
        ? AppPermissions[requiredPermissions].roller
        : [];
    const authStore = useAuthenticationStore();

    if (
        (authStore.isAuthenticated() &&
            authStore.userHasPermission(requiredRoles)) ||
        ignoreProtectedComponent
    ) {
        return <>{children}</>;
    } else {
        return <Fragment></Fragment>;
    }
};

export default ProtectedComponent;
