export const Permission = {
    bruger: "Bruger",
    laerer: "Laerer",
    institutionsledelse: "Institutionsledelse",
    supporter: "Supporter",
};

export const RolleMap = new Map<string, string>([
    [Permission.bruger, "Bruger"],
    [Permission.laerer, "Lærer"],
    [Permission.institutionsledelse, "Institutionsledelse"],
    [Permission.supporter, "supporter"],
]);

export enum LoginIdp {
    None = 0,
    Unilogin = 1,
    Nemlogin = 2,
    SUnilogin = 3,
    StilNemlogin = 4,
    StilAdlogin = 5,
}

export enum Institutionstype {
    Ukendt = 0,
    Dagtilbud = 1,
    Skole = 2,
    Kommune = 3,
    Omraade = 4,
}

export type Institution = {
    institutionsnavn: string;
    institutionsnummer: string;
    institutionstype: Institutionstype;
    kommunekode?: string;
    daginstitution?: Institution;
    normeringafproevning: boolean;
};

export type AktivInstitution = Institution & {
    loggetIndMedValidIdP: boolean;
};

export type AuthenticatedUser = {
    navn: string;
    unilogin: string;
    roller: string[];
    institutioner: string[];
    aktivInstitution: string;
    aktivInstitutionNavn: string;
    cprUuid: string;
    loginIdp: LoginIdp;
};

export const emptyUser: AuthenticatedUser = {
    navn: "",
    roller: new Array<string>(),
    aktivInstitution: "",
    institutioner: new Array<string>(),
    unilogin: "",
    aktivInstitutionNavn: "",
    cprUuid: "",
    loginIdp: LoginIdp.None,
};

export const emptyInstitution: AktivInstitution = {
    institutionsnavn: "",
    institutionstype: Institutionstype.Ukendt,
    kommunekode: "",
    institutionsnummer: "",
    daginstitution: undefined,
    normeringafproevning: false,
    loggetIndMedValidIdP: false,
};
