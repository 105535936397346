import React, { FunctionComponent } from "react";
import Page from "../components/Page";
import forsideImg from "../assets/img/forside-img.jpg";
import "../assets/stylesheets/Forside.css";
import { MenuSetup, Side } from "../helpers/RouteSetup";
import { useHistory } from "react-router-dom";
import { useAuthenticationStore } from "../context/store";

const Forside: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const history = useHistory();

    const registrering = () => {
        if (!authenticationStore.loggedIn()) {
            history.push(MenuSetup[Side.logind].path);
        } else if (!authenticationStore.hasAktivInstitution()) {
            history.push(MenuSetup[Side.vaelgSkole].path);
        } else {
            history.push(MenuSetup[Side.overblik].path);
        }
    };

    return (
        <Page>
            <div className="container page-container forside-container">
                <div className="sp-col-left">
                    <img
                        id="forside-img0"
                        src={forsideImg}
                        alt="Forside billede"
                    ></img>
                    <div className="btn-holder"></div>
                    <button
                        className="link-btn button button-primary"
                        onClick={() => registrering()}
                    >
                        Registrer og indberet
                    </button>
                </div>

                <div className="col-4 col-sm-4 col-md-4 col-lg-4 sp-col-right">
                    <div className="sp-text-col">
                        <h2 className="h2">
                            Sprogprøver i<br /> grundskolen
                        </h2>
                        <p>
                            Der skal gennemføres sprogprøver på grundskoler og
                            afdelinger af grundskoler med over 30 procent elever
                            bosat i boligområder, der inden for de seneste tre
                            år har været på Transport- og Boligministeriets
                            liste over udsatte boligområder. Sprogprøvekravet
                            gælder også frie skoler.
                        </p>
                        <div className="action-links">
                            <ul className="nobullet-list">
                                <li>
                                    <a
                                        href="https://www.uvm.dk/folkeskolen/laering-og-laeringsmiljoe/sprogproever"
                                        className="icon-link"
                                    >
                                        Information om sprogprøverne
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.xn--prvebanken-1cb.dk/proevematerialer/SPR"
                                        className="icon-link"
                                    >
                                        Materiale til sprogprøver
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Forside;
