import React, { FunctionComponent } from 'react';
import '../../assets/stylesheets/Forside.css'
import SupportPage from './SupportPage';

const SupportHome: FunctionComponent = () => {

    return (
        <SupportPage>
            <h1>Support sider</h1>
        </SupportPage>
    )
}

export default SupportHome;