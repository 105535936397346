/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { LogError } from "./ErrorService";

const ApiService = <T>(
    url: string,
    cached = false,
    method = "get",
    data: any | undefined = undefined,
    customHeaders: any | undefined = undefined,
    skipLogging = false,
    expiration: number = 30 * 60 * 1000
): Promise<T> => {
    const callCachedApi = async (): Promise<T> => {
        const cachedItem = await asyncSessionStorage.getItem(url);

        if (cachedItem != null) {
            return cachedItem;
        }
        const fetchedItem = await _callApi().catch((error) => {
            throw error;
        });
        return asyncSessionStorage.setItem(url, fetchedItem, expiration);
    };

    const callApi = async (): Promise<T> => {
        return await _callApi().catch((error) => {
            throw error;
        });
    };

    const _callApi = async (): Promise<T> => {
        const headers =
            customHeaders ??
            {
                /* Authorization: 'Bearer ' + token */
            };
        const finalUrl =
            url.startsWith("http:") || url.startsWith("https:")
                ? url
                : `/${url}`;
        try {
            const response =
                method.toLowerCase() === "post"
                    ? await axios.post<T>(finalUrl, data, {
                          headers: headers,
                          withCredentials: true,
                      })
                    : method.toLowerCase() === "put"
                    ? await axios.put<T>(finalUrl, data, {
                          headers: headers,
                          withCredentials: true,
                      })
                    : method.toLowerCase() === "patch"
                    ? await axios.patch<T>(finalUrl, data, {
                          headers: headers,
                          withCredentials: true,
                      })
                    : method.toLowerCase() === "delete"
                    ? await axios.delete<T>(finalUrl, {
                          headers: headers,
                          withCredentials: true,
                      })
                    : await axios.request<T>({
                          url: finalUrl,
                          headers: headers,
                          withCredentials: true,
                      });

            return response.data;
        } catch (error) {
            if (!skipLogging) {
                LogError(`ApiCall to ${finalUrl} failed: ${error}`);
            }
            throw error;
        }
    };

    return cached ? callCachedApi() : callApi();
};

const asyncSessionStorage = {
    setItem: async function (key: string, value: any, expiration: number) {
        await null;
        const newItem = {
            expiration: expiration,
            timestamp: Date.now(),
            data: value,
        };
        sessionStorage.setItem(key, JSON.stringify(newItem));
        return value;
    },
    getItem: async function (key: string): Promise<any> {
        await null;
        const cachedItem = sessionStorage.getItem(key);
        if (cachedItem == null) return null;

        const parsedCachedItem: CacheItem = JSON.parse(cachedItem);
        const currentTimestamp = Date.now();
        const cachedTimestamp = parsedCachedItem.timestamp;
        const cachedExpiration = parsedCachedItem.expiration;
        if (currentTimestamp - cachedExpiration > cachedTimestamp) {
            return null;
        }

        return parsedCachedItem.data;
    },
    removeItem: async function (key: string) {
        await null;
        sessionStorage.removeItem(key);
    },
};

type CacheItem = {
    forceReloadAt: number;
    serverTime: number;
    timestamp: number;
    expiration: number;
    data: unknown;
};

export function timeout(delay: number): any {
    return new Promise((res) => setTimeout(res, delay));
}

export default ApiService;
