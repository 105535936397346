export type PermissionSet = {
    index: number;
    roller: string[];
};

export enum Permissions {
    laerer = 0,
    institutionsledelse = 1,
    support = 2,
}

export const rolleListe: string[] = [
    "Laerer",
    "Institutionsledelse",
    "Supporter",
];

const AppPermissions: PermissionSet[] = [
    {
        index: Permissions.laerer,
        roller: ["Laerer"],
    },

    {
        index: Permissions.institutionsledelse,
        roller: ["Institutionsledelse", "Supporter"],
    },

    {
        index: Permissions.support,
        roller: ["Supporter"],
    },
];

export default AppPermissions;
