import React, { FunctionComponent } from 'react';
import Page from '../components/Page';
import '../assets/stylesheets/Indberetning.css';
import { BackToStartLink } from './Overblik';
import { Link } from 'react-router-dom';
import { MenuSetup, Side } from '../helpers/RouteSetup';
import { indberetningsFristdato } from '../utils/AppConst';

const Indberetning: FunctionComponent = () => {
    return (
        <Page>
            <div className="container">
            <BackToStartLink />
                <div className="page-container">
                    <h1>Indberet skolens resultater</h1>
                    <p>I kan registrere og redigere i indberetningen indtil den {indberetningsFristdato}.</p>
                    <p>Skolen skal, jf. §19 i bekendtgørelse om obligatoriske sprogprøver i grundskolen, indberette flere forskellige oplysninger:</p>
                    <div className="sp-tab-2">
                        <h2 className="h4">Elever i børnehaveklasse</h2>
                        <ul>
                            <li>Resultat af elevens sprogprøveforsøg (hvis I bruger Sprogprøver.dk, vil de oplysninger I har registreret blive læst over i indberetningssystemet)</li>
                            <li>Om eleven har deltaget i frivillig sommerskole</li>
                            <li>Om eleven har fået særlig tilladelse til at påbegynde 1. klasse på trods af ikke at have bestået 3. eller 4. forsøg (jf. § 11a, stk. 5 i folkeskoleloven)</li>
                            <li>Om eleven rykker op til næste klassetrin eller går børnehaveklassen om</li>
                        </ul>
                        <h2 className="h4">Elever i 1. - 9. klasse</h2>
                        <ul>
                            <li>Resultat af elevens sprogprøveforsøg</li>
                            <li>Om eleven rykker op til næste klassetrin eller går klassetrinnet om</li>
                        </ul>
                        <h2 className="h4">Samlet for skolen</h2>
                        <ul>
                            <li>Antallet af elever, som skolens leder har fritaget fra at gennemføre sprogprøven, jf. §14 i bekendtgørelse om obligatoriske sprogprøver i grundskolen.</li>
                        </ul>
                    </div>
                <Link className="button button-primary" title="Start indberetning" to={MenuSetup[Side.indberetterTrin1].path}>
                    <span>Start indberetning</span>
                </Link></div>
            </div>
        </Page>
    )
}

export default Indberetning;