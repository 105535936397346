import React, { FunctionComponent, useState, ReactElement } from 'react';
import { ElevIndberetningData, ElevUdpegningData } from '../../helpers/EleverTyper';

type MicromodalProps = {
    id: string,
    status: boolean,
    elev: ElevUdpegningData | ElevIndberetningData | undefined,
    title: string,
    content: ReactElement,
    footer: ReactElement
    onCloseModal: ((boolean) => void)
};

const Micromodal: FunctionComponent<MicromodalProps> = (props) => {
    const [status, setStatus] = useState<boolean>(props.status);

    function Close() {
        setStatus(false);
        props.onCloseModal(false);
    }

    return (
        <div className={status === false ? "modal" : "modal is-open"} id={props.id} aria-hidden={status === false ? "true" : "false"}>
            <div className="modal__overlay" tabIndex={-1}>
                <div className="modal__container" role="dialog" aria-modal="true"
                    aria-labelledby="modal-title-1">
                    {props.elev === undefined ? <div>Der er sket en fejl.</div> :
                        <div>
                            <div className="modal__header">
                                <h1 className="modal__title h2" id="modal-title-1">{props.title}</h1>
                            </div>
                            <div className="modal__content">
                                {props.content}
                            </div>
                            <div className="modal__footer">
                                {props.footer}
                            </div>
                            <button className="modal__close button button-secondary" aria-label="Close modal" data-micromodal-close onClick={() => Close()} >Luk</button>
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default Micromodal;