import React, { FunctionComponent, useState } from 'react';

type OnDropdownChangePropType = (id: string, value: string) => void;

type DropdownProps = {
    options: Array<number | string>,
    selected: number | string,
    selectid: string,
    onChangeProp?: OnDropdownChangePropType,
    width?: string | number
};

const Dropdown: FunctionComponent<DropdownProps> = (props) => {
    const [selected, setSelected] = useState<string>(String(props.selected));

    const dropdownStyle = {
        width: props.width
    }

    var options = props.options.map((n: number | string) => {
        return <option value={n} key={props.selectid + '_' + n}>{n}</option>
    });

    function onChangeEvent(event) {
        var value = event.target.value; 
        if (props.onChangeProp) {
            props.onChangeProp(props.selectid, value);
        }
        setSelected(value);
    }

    return <select id={props.selectid} value={selected} onChange={(e) => onChangeEvent(e)} className='form-select'  style={dropdownStyle} aria-label="Dropdown">
        {options}
    </select>;
};

export default Dropdown;