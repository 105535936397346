import React, { useState, FunctionComponent, CSSProperties } from 'react';

type ElevInfoProps = {
    navn: string;
    navnMaxWidth: string,
    klassenavn: string;
    klassetrin: number;
    unilogin: string | undefined;
};

const ElevInfo: FunctionComponent<ElevInfoProps> = (props) => {
    const [nameClass, setNameClass] = useState<string>("");
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

    const NameStyle: CSSProperties = {
        whiteSpace: 'nowrap',
        maxWidth: props.navnMaxWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0
    };

    const handleMouseEnter = () => {
        setTimer(
            setTimeout(() => {
                setNameClass("popover");
            }, 500)
        );
    }

    const handleMouseLeave = () => {
        if (timer !== undefined) {
            clearTimeout(timer);
        }
        setNameClass("");
    }

    const Navn = (navn: string) => {
        return (
            <div style={{ cursor: 'pointer', display: 'inline' }} className={nameClass} data-tooltip={navn} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div style={NameStyle}>
                    <b>{navn}</b>
                </div>
            </div>
        )
    }

    return (
        <div>
            {Navn(props.navn)}
            {props.unilogin &&
                <div>
                    {props.unilogin}
                </div>
            }
            <div>
                {props.klassenavn}
            </div>
            <div>
                {props.klassetrin}. klassetrin
            </div>
        </div>
    )
}

export default ElevInfo;