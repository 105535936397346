import React, {
    ReactElement,
    useState,
    FunctionComponent,
    useEffect,
} from "react";
import "../assets/stylesheets/Indberetning.css";
import Table from "../components/common/Table";
import Tjekboks from "../components/common/Tjekboks";
import Radioknapper from "../components/common/Radioknapper";
import Statusbadge from "../components/common/Statusbadge";
import Popover from "../components/common/Popover";
import { sortElevData } from "../helpers/SortHelper";
import { Prompt, useHistory } from "react-router-dom";
import ApiService from "../services/ApiService";
import PageLoader from "../components/PageLoader";
import Page from "../components/Page";
import Sidenavigation from "../components/common/Sidenavigation";
import {
    ElevIndberetningData,
    ElevOpdatering,
    ElevResultater,
    ElevOpdateringKlassetrin0,
} from "../helpers/EleverTyper";
import { Side, MenuSetup } from "../helpers/RouteSetup";
import Tilbage from "../components/common/Tilbage";
import Micromodal from "../components/common/Micromodal";
import ElevInfo from "../components/common/ElevInfo";
import { useAuthenticationStore } from "../context/store";

const SaerligTilladelseHjaelpTekst =
    "Det følger af §11a, stk 5 i folkeskoleloven, at skolens leder i helt særlige tilfælde kan beslutte at en elev, der ikke har bestået sprogprøven, på trods heraf kan påbegynde 1. klasse. Det er en forudsætning, at skolelederen vurderer, at elevens faglige niveau er tilstrækkeligt til at påbegynde undervisningen i 1. klasse";

type IndberetterElevdataProps = {
    klassetrin0: boolean;
};

const IndberetterElevdata: FunctionComponent<IndberetterElevdataProps> = (
    props: IndberetterElevdataProps
) => {
    const authenticationStore = useAuthenticationStore();
    const skoleaar = authenticationStore.getSkoleAar();
    const [elevData, setElevData] = useState<
        ElevIndberetningData[] | undefined
    >(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [opdateret, setOpdateret] = useState<boolean>(false);
    const [opdateringer] = useState<ElevOpdatering[]>(
        new Array<ElevOpdatering>()
    );
    const [elevforsoeg, setElevforsoeg] = useState<ElevResultater[]>(
        new Array<ElevResultater>()
    );
    const [micromodalMode, setMicromodalMode] = useState<boolean>(false);
    const [micromodalElev, setMicromodalElev] = useState<
        ElevIndberetningData | undefined
    >(undefined);
    const history = useHistory();
    const columnWidths: string[] = [
        "220px",
        "155px",
        "180px",
        "190px",
        "180px",
    ];

    useEffect(() => {
        async function callApi() {
            ApiService<ElevIndberetningData[]>(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/hentindberetning/" +
                    skoleaar +
                    "/klassetrin0/" +
                    (props.klassetrin0 ? "true" : "false")
            )
                .then((response) => {
                    setElevData(response);
                })
                .catch((e) => {
                    setError(e);
                });
        }
        callApi();
    }, [
        authenticationStore.authenticatedUser,
        elevforsoeg,
        props.klassetrin0,
        skoleaar,
        authenticationStore.aktivInstitution,
    ]);

    const next = (side: number) => {
        if (opdateringer.length > 0) {
            ApiService(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/indberetning/" +
                    skoleaar +
                    "/trin/" +
                    (props.klassetrin0 ? "1" : "2"),
                false,
                "post",
                opdateringer
            )
                .then(() => {
                    setOpdateret(false);
                    history.push(MenuSetup[side].path);
                })
                .catch((e) => {
                    setError(e);
                });
        } else {
            history.push(MenuSetup[side].path);
        }
    };

    const initElevOpdatering = (elev: ElevIndberetningData): ElevOpdatering => {
        return props.klassetrin0
            ? {
                  unilogin: elev.unilogin,
                  sommerskole: elev.sommerskole ? elev.sommerskole : false,
                  saerligTilladelse: elev.saerligTilladelse
                      ? elev.saerligTilladelse
                      : false,
                  rykkerOp: elev.rykkerOp,
              }
            : {
                  unilogin: elev.unilogin,
                  rykkerOp: elev.rykkerOp,
              };
    };

    const setSommerskole = (elev: ElevIndberetningData, value: boolean) => {
        if (!props.klassetrin0) return;
        var opdatering = opdateringer.filter(
            (o) => o.unilogin === elev.unilogin
        );
        if (opdatering.length === 0) {
            const eo = initElevOpdatering(elev) as ElevOpdateringKlassetrin0;
            eo.sommerskole = value;
            opdateringer.push(eo);
        } else {
            (opdatering[0] as ElevOpdateringKlassetrin0).sommerskole = value;
        }
        setOpdateret(true);
    };

    const setTilladelse = (elev: ElevIndberetningData, value: boolean) => {
        if (!props.klassetrin0) return;
        var opdatering = opdateringer.filter(
            (o) => o.unilogin === elev.unilogin
        );
        if (opdatering.length === 0) {
            const eo = initElevOpdatering(elev) as ElevOpdateringKlassetrin0;
            eo.saerligTilladelse = value;
            opdateringer.push(eo);
        } else {
            (opdatering[0] as ElevOpdateringKlassetrin0).saerligTilladelse =
                value;
        }
        setOpdateret(true);
    };

    const rykkerOp = (value: string): boolean | undefined => {
        return value === "Ja" ? true : value === "Nej" ? false : undefined;
    };

    function setRykkerOp(elev: ElevIndberetningData, value: string) {
        var opdatering = opdateringer.filter(
            (o) => o.unilogin === elev.unilogin
        );
        if (opdatering.length === 0) {
            const eo = initElevOpdatering(elev);
            eo.rykkerOp = rykkerOp(value);
            opdateringer.push(eo);
        } else {
            opdatering[0].rykkerOp = rykkerOp(value);
        }
        setOpdateret(true);
    }

    function openIndvidRedigering(elev: ElevIndberetningData) {
        setMicromodalElev(elev);
        setMicromodalMode(true);
    }

    function annuller() {
        setMicromodalMode(false);
    }

    function changeResultat(selectid: string, value: string) {
        if (micromodalElev === undefined) {
            setElevforsoeg(new Array<ElevResultater>());
            return;
        }
        var boolValue =
            value === "Intet resultat"
                ? undefined
                : value === "Sprogparat"
                ? true
                : false;
        var forsoeg = Number(selectid.substr(0, 1));
        var resultatToUpdate = elevforsoeg.filter(
            (e) =>
                e.forsoeg === forsoeg &&
                e.unilogin === micromodalElev.unilogin &&
                e.klassetrin === micromodalElev.klassetrin
        );
        if (resultatToUpdate.length > 0) {
            resultatToUpdate[0].sprogparat = boolValue;
        } else {
            const newElevforsoegData: ElevResultater = {
                unilogin: micromodalElev.unilogin,
                klassetrin: micromodalElev.klassetrin,
                sprogparat: boolValue,
                forsoeg: forsoeg,
            };
            elevforsoeg.push(newElevforsoegData);
        }
    }

    function saveUpdatedResults() {
        //send
        ApiService<boolean>(
            "institution/" +
                authenticationStore.aktivInstitution.institutionsnummer +
                "/opdatersprogforsoeg/" +
                skoleaar,
            false,
            "post",
            elevforsoeg
        )
            .then((response) => {
                if (response === false) throw error;
                setElevforsoeg(new Array<ElevResultater>());
                setMicromodalMode(false);
            })
            .catch((e) => {
                setError(e);
            });
    }

    const micromodalTitle =
        micromodalElev !== undefined
            ? micromodalElev.navn + ", " + micromodalElev.klassenavn
            : "Hov!";

    const micromodalContent =
        micromodalElev !== undefined ? (
            <div className="row">
                <div className="col">
                    <h2 className="h4">1. forsøg</h2>
                    <div>
                        <Radioknapper
                            selected={
                                micromodalElev.resultater[0] === null
                                    ? "Intet resultat"
                                    : micromodalElev.resultater[0]
                                    ? "Sprogparat"
                                    : "Ikke sprogparat"
                            }
                            options={[
                                "Sprogparat",
                                "Ikke sprogparat",
                                "Intet resultat",
                            ]}
                            selectid={"1_parat_" + micromodalElev.unilogin}
                            groupClassName="small-text"
                            buttonClassName="form-radio radio-large"
                            onChangeProp={changeResultat}
                        ></Radioknapper>
                    </div>
                </div>
                <div className="col">
                    <h2 className="h4">2. forsøg</h2>
                    <div>
                        <Radioknapper
                            selected={
                                micromodalElev.resultater[1] === null
                                    ? "Intet resultat"
                                    : micromodalElev.resultater[1]
                                    ? "Sprogparat"
                                    : "Ikke sprogparat"
                            }
                            options={[
                                "Sprogparat",
                                "Ikke sprogparat",
                                "Intet resultat",
                            ]}
                            selectid={"2_parat_" + micromodalElev.unilogin}
                            groupClassName="small-text"
                            buttonClassName="form-radio radio-large"
                            onChangeProp={changeResultat}
                        ></Radioknapper>
                    </div>
                </div>
                <div className="col">
                    <h2 className="h4">3. forsøg</h2>
                    <div>
                        <Radioknapper
                            selected={
                                micromodalElev.resultater[2] === null
                                    ? "Intet resultat"
                                    : micromodalElev.resultater[2]
                                    ? "Sprogparat"
                                    : "Ikke sprogparat"
                            }
                            options={[
                                "Sprogparat",
                                "Ikke sprogparat",
                                "Intet resultat",
                            ]}
                            selectid={"3_parat_" + micromodalElev.unilogin}
                            groupClassName="small-text"
                            buttonClassName="form-radio radio-large"
                            onChangeProp={changeResultat}
                        ></Radioknapper>
                    </div>
                </div>
                <div className="col">
                    <h2 className="h4">4. forsøg</h2>
                    <div>
                        <Radioknapper
                            selected={
                                micromodalElev.resultater[3] === null
                                    ? "Intet resultat"
                                    : micromodalElev.resultater[3]
                                    ? "Sprogparat"
                                    : "Ikke sprogparat"
                            }
                            options={[
                                "Sprogparat",
                                "Ikke sprogparat",
                                "Intet resultat",
                            ]}
                            selectid={"4_parat_" + micromodalElev.unilogin}
                            groupClassName="small-text"
                            buttonClassName="form-radio radio-large"
                            onChangeProp={changeResultat}
                        ></Radioknapper>
                    </div>
                </div>
            </div>
        ) : (
            <div>Der er sket en fejl.</div>
        );

    const micromodalFooter = (
        <div>
            <button
                className="button button-primary"
                aria-label="Fx bekræft handling"
                onClick={() => saveUpdatedResults()}
            >
                Gem
            </button>
            <button
                className="button button-secondary"
                data-micromodal-close
                onClick={() => annuller()}
                aria-label="Fx lukker modal vinduet"
            >
                Annuller
            </button>
        </div>
    );

    const micromodalHtml =
        micromodalMode && micromodalElev !== undefined ? (
            <Micromodal
                id="udp-modal"
                status={true}
                elev={micromodalElev}
                title={micromodalTitle}
                content={micromodalContent}
                footer={micromodalFooter}
                onCloseModal={() => annuller()}
            />
        ) : null;

    const uniloginForDisplay = (
        elev: ElevIndberetningData
    ): string | undefined => {
        var navne = elevData?.filter((e) => e.navn === elev.navn);
        if (navne && navne.length > 1) return elev.unilogin;
        return undefined;
    };

    const eleverTable = (elevData: ElevIndberetningData[] | undefined) => {
        let table = <p>Ingen elever</p>;
        if (elevData !== undefined && elevData.length > 0) {
            let headers: (string | JSX.Element)[] = [
                "Elev",
                "Resultat fra sprogprøver",
            ];
            if (props.klassetrin0) {
                headers.push("Sommerskole (frivillig)");
                headers.push(
                    <div>
                        Særlig tilladelse til start i 1. kl{" "}
                        <Popover
                            tekst={SaerligTilladelseHjaelpTekst}
                            icon="question-circle"
                        ></Popover>
                    </div>
                );
            }
            headers.push("Rykker op til næste klassetrin");
            const rows = sortElevData(elevData).map(
                (elev: ElevIndberetningData, i: number) => {
                    let cells: Array<ReactElement | string> = [];
                    cells.push(
                        <ElevInfo
                            unilogin={uniloginForDisplay(elev)}
                            navn={elev.navn}
                            navnMaxWidth={columnWidths[0]}
                            klassenavn={elev.klassenavn}
                            klassetrin={elev.klassetrin}
                        />
                    );

                    let badges = elev.resultater.map(
                        (parat: boolean, j: number) => {
                            return parat === undefined ||
                                parat === null ? null : (
                                <button
                                    className="button-unstyled"
                                    key={"result" + elev.unilogin + j}
                                    onClick={() => openIndvidRedigering(elev)}
                                >
                                    <Statusbadge
                                        id={"res_" + j + "_elev_" + i}
                                        key={"res_" + j + "_elev_" + i}
                                        label={
                                            parat
                                                ? j + 1 + ". SPROGPARAT"
                                                : j + 1 + ". IKKE SPROGPARAT"
                                        }
                                        className={
                                            "pointer badge-small " +
                                            (parat
                                                ? "badge-success"
                                                : "badge-error")
                                        }
                                    ></Statusbadge>
                                </button>
                            );
                        }
                    );
                    cells.push(
                        badges.every((b) => b === null) ? (
                            <button
                                className="button-link"
                                onClick={() => openIndvidRedigering(elev)}
                            >
                                Registrer resultater
                            </button>
                        ) : (
                            <div>{badges}</div>
                        )
                    );
                    if (props.klassetrin0) {
                        cells.push(
                            <Tjekboks
                                id={"sommerskole_" + i}
                                value={"sommerskole"}
                                label={"Ja, har deltaget"}
                                changed={(value) => setSommerskole(elev, value)}
                                checked={elev.sommerskole ? true : false}
                                inputClassName="form-checkbox checkbox-large"
                                labelClassName="small-text"
                            ></Tjekboks>
                        );
                        cells.push(
                            <Tjekboks
                                id={"tilladelse_" + i}
                                value={"tilladelse"}
                                label={"Ja, har tilladelse"}
                                changed={(value) => setTilladelse(elev, value)}
                                checked={elev.saerligTilladelse ? true : false}
                                inputClassName="form-checkbox checkbox-large"
                                labelClassName="small-text"
                            ></Tjekboks>
                        );
                    }
                    cells.push(
                        <Radioknapper
                            selected={
                                elev.rykkerOp === undefined ||
                                elev.rykkerOp === null
                                    ? "Ikke afgjort"
                                    : elev.rykkerOp
                                    ? "Ja"
                                    : "Nej"
                            }
                            options={["Ja", "Nej", "Ikke afgjort"]}
                            selectid={"rykkerop_" + elev.unilogin}
                            groupClassName="small-text"
                            buttonClassName="form-radio radio-large"
                            onChangeProp={(id, value) =>
                                setRykkerOp(elev, value)
                            }
                        ></Radioknapper>
                    );
                    return { className: "", cells };
                }
            );
            table = (
                <Table
                    id="indb_trin1"
                    className="table--borderless"
                    headers={headers}
                    dataLabels={[]}
                    widths={columnWidths}
                    alignments={[]}
                    wordBreaks={["break-all"]}
                    rows={rows}
                    footer={""}
                />
            );
        }
        return table;
    };

    return (
        <PageLoader dataPresent={elevData} error={error}>
            <Page>
                <div className="container page-container sp-indb-container">
                    <div className="sidenav sp-indb-sidenav">
                        <Sidenavigation
                            menuIds={[
                                Side.indberetterTrin1,
                                Side.indberetterTrin2,
                                Side.indberetterTrin3,
                                Side.indberetterTrin4,
                            ]}
                            onClick={(side) => next(side)}
                            navStyle={{ width: "240px" }}
                        ></Sidenavigation>
                    </div>
                    <div className="content sp-indb-content">
                        <p className="h6 m-0">INDBERET SKOLENS RESULTATER</p>
                        <h1>
                            Elever i{" "}
                            {props.klassetrin0
                                ? "børnehaveklasse"
                                : "1. - 9. klasse"}
                        </h1>
                        <Prompt
                            when={opdateret}
                            message="Vil du forlade siden uden at gemme?"
                        />
                        {eleverTable(elevData)}
                        {micromodalHtml}

                        <div style={{ marginTop: "20px" }}>
                            <button
                                className="button button-primary"
                                onClick={() =>
                                    next(
                                        props.klassetrin0
                                            ? Side.indberetterTrin2
                                            : Side.indberetterTrin3
                                    )
                                }
                            >
                                Næste
                            </button>
                        </div>
                        <div className="navbar-inner navbar-context-actions">
                            <Tilbage
                                text="Tilbage"
                                onClick={() =>
                                    next(
                                        props.klassetrin0
                                            ? Side.indberetning
                                            : Side.indberetterTrin1
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </Page>
        </PageLoader>
    );
};

export default IndberetterElevdata;
