import React, { FunctionComponent } from 'react';
import Page from '../components/Page';

const Fejl: FunctionComponent = () => {

    return (
        <Page>
            <div className="container alert alert-error" role="alert">
                <div className="alert-body">
                    <p className="alert-heading">Undskyld. Der er desværre opstået en fejl.</p>
                    <p className="alert-text">Vi prøver at løse problemet hurtigst muligt. Prøv igen senere.</p>
                </div>
            </div>
        </Page>
    );
}

export default Fejl;
