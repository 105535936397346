import React, { FunctionComponent } from "react";
import { useLocation, Link } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../assets/img/uvm.svg";
import ProtectedComponent from "./ProtectedComponent";
import AppPermissions, { Permissions, rolleListe } from "./Permissions";
import { MenuSetup, Side } from "../helpers/RouteSetup";
import { useAuthenticationStore } from "../context/store";
import { RolleMap } from "../types/AppTypes";

type HeaderProps = {
    navigation: boolean;
    loginButton: boolean;
};

const Header: FunctionComponent<HeaderProps> = (props) => {
    let location = useLocation();
    const authenticationStore = useAuthenticationStore();
    const visNavigation = props.navigation;
    const visLogin = props.loginButton;

    return (
        <header className="header">
            <div className="portal-header">
                <div className="container portal-header-inner">
                    <Link
                        className="nav-link"
                        title={MenuSetup[Side.root].menuTekst}
                        to={MenuSetup[Side.root].path}
                        aria-label="Logo"
                    >
                        <LogoSvg />
                    </Link>
                    <button
                        className="button button-tertiary button-menu-open js-menu-open ml-auto d-print-none"
                        aria-haspopup="menu"
                        title="Åben mobil menu"
                    >
                        Menu
                    </button>

                    {visLogin && (
                        <>
                            {authenticationStore.loggedIn() && (
                                <div className="portal-info">
                                    <p className="user">
                                        <span className="weight-semibold">
                                            {
                                                authenticationStore
                                                    .authenticatedUser.navn
                                            }{" "}
                                            -{" "}
                                            {
                                                authenticationStore
                                                    .authenticatedUser
                                                    .aktivInstitution
                                            }{" "}
                                            (
                                            {
                                                authenticationStore
                                                    .aktivInstitution
                                                    .institutionsnummer
                                            }
                                            )
                                        </span>
                                        <br />
                                        {authenticationStore.authenticatedUser.roller
                                            .filter((rolle) =>
                                                rolleListe.includes(rolle)
                                            )
                                            .map((r, i) =>
                                                i === 0
                                                    ? `${RolleMap.get(r)
                                                          ?.charAt(0)
                                                          .toUpperCase()}${RolleMap.get(
                                                          r
                                                      )?.slice(1)}`
                                                    : RolleMap.get(r)
                                            )
                                            .join(", ")}
                                    </p>
                                    <a
                                        href={`/auth/stillogin/logout?returnurl=/logaf`}
                                        role="button"
                                        className="button alert-leave d-print-none button-secondary"
                                        onClick={() => {
                                            sessionStorage.clear();
                                        }}
                                    >
                                        Log ud
                                    </a>
                                </div>
                            )}

                            {!authenticationStore.loggedIn() && (
                                <div className="portal-info">
                                    <Link
                                        to={"/logind"}
                                        role="button"
                                        className="button alert-leave d-print-none button-primary"
                                    >
                                        Log ind
                                    </Link>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="solution-header">
                <div className="container solution-header-inner">
                    <div className="solution-heading">
                        <Link
                            className="nav-link"
                            title={MenuSetup[Side.root].menuTekst}
                            to={MenuSetup[Side.root].path}
                            aria-label="Sprogprøver.dk Indberetning"
                        >
                            <span>Sprogprøver.dk - Indberetning</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="overlay"></div>

            {visNavigation && (
                <nav className="nav">
                    <button
                        className="button button-secondary button-menu-close js-menu-close"
                        title="Luk mobil menu"
                    >
                        <svg
                            className="icon-svg "
                            focusable="false"
                            aria-hidden="true"
                        >
                            <use xlinkHref="#close"></use>
                        </svg>
                        Luk
                    </button>
                    <div className="navbar navbar-primary">
                        <div className="container navbar-inner">
                            <ul className="nav-primary">
                                <li
                                    className={
                                        location.pathname ===
                                        MenuSetup[Side.root].path
                                            ? "current"
                                            : ""
                                    }
                                >
                                    <Link
                                        className="nav-link"
                                        title={MenuSetup[Side.root].menuTekst}
                                        to={MenuSetup[Side.root].path}
                                    >
                                        <span>
                                            {MenuSetup[Side.root].menuTekst}
                                        </span>
                                    </Link>
                                </li>
                                <ProtectedComponent
                                    requiredPermissions={
                                        Permissions.institutionsledelse
                                    }
                                    requireLicence={true}
                                >
                                    <li
                                        className={
                                            location.pathname.startsWith(
                                                MenuSetup[Side.overblik].path
                                            )
                                                ? "current"
                                                : ""
                                        }
                                    >
                                        <Link
                                            className="nav-link"
                                            title="Registrering og indberetning"
                                            to={MenuSetup[Side.overblik].path}
                                        >
                                            <span>
                                                Registrering og indberetning
                                            </span>
                                        </Link>
                                    </li>
                                </ProtectedComponent>

                                <ProtectedComponent
                                    requiredPermissions={Permissions.support}
                                    requireLicence={false}
                                >
                                    <li
                                        className={
                                            location.pathname ===
                                            MenuSetup[Side.support].path
                                                ? "current"
                                                : ""
                                        }
                                    >
                                        <Link
                                            className="nav-link"
                                            title="STIL Supporter"
                                            to={MenuSetup[Side.support].path}
                                        >
                                            <span>
                                                {
                                                    MenuSetup[Side.support]
                                                        .menuTekst
                                                }
                                            </span>
                                        </Link>
                                    </li>
                                </ProtectedComponent>

                                {authenticationStore.loggedIn() &&
                                    authenticationStore.authenticatedUser
                                        .institutioner.length > 1 && (
                                        <li
                                            className={
                                                location.pathname ===
                                                MenuSetup[Side.vaelgSkole].path
                                                    ? "current"
                                                    : ""
                                            }
                                        >
                                            <Link
                                                className="nav-link"
                                                title="Vælg skole"
                                                to={
                                                    MenuSetup[Side.vaelgSkole]
                                                        .path
                                                }
                                            >
                                                <span>
                                                    {
                                                        MenuSetup[
                                                            Side.vaelgSkole
                                                        ].menuTekst
                                                    }
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                            </ul>
                        </div>
                    </div>
                </nav>
            )}
        </header>
    );
};

export default Header;
