import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SearchboxProps = {
    expanded: boolean,
    type?: string,
    title?: string,
    helpText?: string,
    icon?: boolean
};

const Searchbox: FunctionComponent<SearchboxProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(props.expanded);

    if (props.type === "box") {
        return <div>
            <ul className="accordion accordion-bordered">
                <li>
                    <button className="accordion-button" aria-expanded={expanded === true ? "true" : "false"} aria-label="Search" aria-controls="b-a5" onClick={(e) => setExpanded(!expanded)}>Søg</button>
                    <div id="b-a5" aria-hidden={expanded === true ? "false" : "true"} className="accordion-content">
                        {props.title ? <label className="form-label">{props.title}</label> : null}
                        {props.helpText ? <span className="form-hint">{props.helpText}</span> : null}
                        {props.children}
                    </div>
                </li>
            </ul>
        </div>
    } else {
        return <fieldset>
            <legend>
                {props.title ? <label className="form-label">{props.title}</label> : null}
            </legend>
            {props.children}
            <div className="form-group search">
                {props.icon === undefined || props.icon === false ? null :
                    <button className="button button-search" id="searchbtn" type="submit" aria-label="Search">
                        <FontAwesomeIcon icon={['fas', 'search']} />
                    </button>}
            </div>
        </fieldset>
    }


};

Searchbox.defaultProps = {
    expanded: true
}


export default Searchbox;