import React, { FunctionComponent } from 'react';
import Header from './Header';
import Footer from './Footer';

type PageProps = {
}

const Page: FunctionComponent<PageProps> = (props) => {
    return (
        <div>
            <Header navigation={true} loginButton={true} />
            {props.children}
            <Footer />
        </div>
    );
}

export default Page;
