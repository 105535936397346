import React, {FunctionComponent} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PopoverProps = {
    tekst: string,
    icon: string
};

function renderIcon(icon: string){
    switch(icon){
        case 'question-circle':
            return <FontAwesomeIcon icon={['far', 'question-circle']} />;
        default: 
            return <FontAwesomeIcon icon={['far', 'times-circle']} />
    }
}

const Popover: FunctionComponent<PopoverProps> = (props) => {
    return (
            <div className='popover' data-tooltip={props.tekst}>
                {renderIcon(props.icon)}
            </div>
        );
};

export default Popover;