import React from "react";
import AppContent from "./components/AppContent";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { AuthenticationStoreProvider } from "./context/store";

library.add(far, fas);

const App = () => {
    return (
        <AuthenticationStoreProvider>
            <AppContent />
        </AuthenticationStoreProvider>
    );
};

export default App;
