import React, { FunctionComponent, useEffect, useState } from "react";
import Page from "../components/Page";
import { useHistory, useLocation } from "react-router-dom";
import { MenuSetup, Side, findMenuItem } from "../helpers/RouteSetup";
import config, { LoginConfiguration } from "../context/ClientConfiguration";
import { useAuthenticationStore } from "../context/store";
import { observer } from "mobx-react-lite";

const LoginPage = (): JSX.Element => {
    const { state } = useLocation();
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [loginConfigurations, setLoginConfigurations] = useState<
        LoginConfiguration[]
    >([]);
    const authenticationStore = useAuthenticationStore();
    const history = useHistory();

    useEffect(() => {
        async function callApi() {
            const rcc = await config.getData();
            if (rcc && rcc.loginConfigurations) {
                setLoginConfigurations(rcc.loginConfigurations);

                if (authenticationStore.isAuthenticated()) {
                    setRedirectToReferrer(true);
                }
            }
        }
        callApi();
    }, [authenticationStore.authenticated]);

    if (
        authenticationStore.userLoggedIn &&
        redirectToReferrer === true &&
        state
    ) {
        history.push(state || "/");
    }

    const loginButtons = loginConfigurations.map((l) => (
        <div
            className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 fp-text"
            key={l.endpoint}
        >
            <h2 className="h2">{l.heading ?? "Login"}</h2>
            <a
                href={`/auth/stillogin/login/${l.endpoint}?returnurl=${
                    MenuSetup[Side.vaelgSkole].path
                }`}
                role="button"
                className="button alert-leave d-print-none button-primary"
            >
                {l.buttonText ?? "Login"}
            </a>
        </div>
    ));

    return (
        <Page>
            <div className="container page-container">
                <div className="d-flex flex-column">
                    <div className="row">{loginButtons}</div>
                </div>
            </div>
        </Page>
    );
};

export default observer(LoginPage);
