import { makeAutoObservable } from "mobx";
import {
    AktivInstitution,
    AuthenticatedUser,
    LoginIdp,
    Permission,
    emptyInstitution,
    emptyUser,
} from "../types/AppTypes";
import React, { useContext, useRef } from "react";

export type Institutionsbeskrivelse = {
    institutionsnummer: string;
    roller: string[];
};

export default class AuthenticationStore {
    public authenticated = false;

    userLoggedIn = false;
    authenticatedUser: AuthenticatedUser = emptyUser;
    aktivInstitution: AktivInstitution = emptyInstitution;
    skoleAar: number = -1;
    appInitialized = false;

    isAuthenticated = (): boolean => {
        return this.authenticated;
    };

    getLoginIdp = (): LoginIdp | undefined => {
        if (this.isAuthenticated() && this.authenticatedUser) {
            return this.authenticatedUser.loginIdp;
        }

        return undefined;
    };

    isIdpLoginOk = (): boolean => {
        if (
            this.isAuthenticated() &&
            this.authenticatedUser &&
            this.aktivInstitution
        ) {
            //Hvis brugeren har rettigheder der er SPV relevante så tjek om brugeren også er logget ind med en korrekt Identity Provider for at kunne anvende disse rettigheder.
            if (
                this.userHasPermission([
                    Permission.bruger,
                    Permission.laerer,
                    Permission.institutionsledelse,
                    Permission.supporter,
                ])
            ) {
                return this.aktivInstitution.loggetIndMedValidIdP;
            } else {
                return true;
            }
        }
        return false;
    };

    setApiError?: React.Dispatch<React.SetStateAction<boolean>>;

    constructor() {
        makeAutoObservable(this);
    }

    setUserLoggedIn(userLoggedIn: boolean): void {
        this.userLoggedIn = userLoggedIn;
    }

    clearAktivInstitution(): void {
        this.aktivInstitution = emptyInstitution;
    }

    setAktivInstitution(institution: AktivInstitution): void {
        this.aktivInstitution = institution;
    }

    setAuthenticatedUser(authenticatedUser: AuthenticatedUser): void {
        this.authenticatedUser = authenticatedUser;
        this.authenticated = true;
    }

    loggedIn = (): boolean => {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.cprUuid !== null &&
            this.authenticatedUser.cprUuid.length > 0
        );
    };

    logOut = () => {
        sessionStorage.clear();
        this.authenticated = false;
    };

    insttypeChecked = (): boolean => {
        return (
            this.aktivInstitution &&
            this.aktivInstitution.institutionsnavn.length > 0
        );
    };

    hasAktivInstitution = (): boolean => {
        return (
            this.authenticatedUser.aktivInstitution !== null &&
            this.authenticatedUser.aktivInstitution.length > 0
        );
    };

    getSkoleAar = (): number => {
        return this.skoleAar;
    };

    setSkoleAar = (aar: number) => {
        this.skoleAar = aar;
    };

    getAppInitialized = (): boolean => {
        return this.appInitialized;
    };

    setAppInitialized = (initialized: boolean) => {
        this.appInitialized = initialized;
    };
    /**
     * Hvis brugeren har en af de efterlyste rettigheder så er man godkendt.
     * @param requiredPermissions
     * @returns true hvis bruger har en af rettighederne.
     */
    userHasPermission = (requiredPermissions: string[]): boolean => {
        if (!requiredPermissions) return true; // No permissions required

        if (requiredPermissions.length === 0) return true; // No permissions specified

        let permission = false;
        requiredPermissions.forEach((required) => {
            if (this.authenticatedUser.roller.includes(required)) {
                permission = true;
            }
        });

        return permission;
    };
}

export const AuthenticationStoreContext =
    React.createContext<AuthenticationStore>(
        null as unknown as AuthenticationStore
    );

export const useAuthenticationStore = (): AuthenticationStore =>
    useContext(AuthenticationStoreContext);

type Props = {
    children: React.ReactNode;
};

export function AuthenticationStoreProvider({ children }: Props): JSX.Element {
    const store = useRef(new AuthenticationStore());

    return (
        <AuthenticationStoreContext.Provider value={store.current}>
            {children}
        </AuthenticationStoreContext.Provider>
    );
}
