import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthenticationStore } from "../context/store";
import AppPermissions from "../components/Permissions";

const ProtectedRoute = ({
    component: Component,
    requiredPermissions: requiredPermissions,
    ...rest
}) => {
    const ignoreProtectedRoute: boolean = false;
    const authenticationStore = useAuthenticationStore();
    const requiredRoles: string[] = requiredPermissions
        ? AppPermissions[requiredPermissions].roller
        : [];

    return (
        <Route
            {...rest}
            render={(props) =>
                (authenticationStore.isAuthenticated() &&
                    authenticationStore.userHasPermission(requiredRoles)) ||
                ignoreProtectedRoute ? (
                    <Component {...props} />
                ) : !authenticationStore.isAuthenticated() ? (
                    <Redirect
                        to={{
                            pathname: "/logind",
                            state: props.location.pathname,
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/rettigheder",
                            state: props.location.pathname,
                        }}
                    />
                )
            }
        />
    );
};

export default ProtectedRoute;
